import ko from 'knockout'

export default function ContactFormModel(screen) {
    var self = this;

    this.SubmitStatusText = ko.observable();
    this.SubmitStatusClass = ko.observable();

    this.Name = ko.observable()
    this.EmailAddress = ko.observable()
    this.Subject = ko.observable()
    this.Message = ko.observable()

    this.Validations = ko.observable();
    this.Required = ko.observable();
    this.IsValid = ko.computed(function () { var vals = self.Validations(); return vals ? vals.length < 1 : false; });
    this.EnableSubmit = ko.computed(() => self.IsValid() && self.Required());

    this.SubmitContactForm = function () {
        var formData = self.GetSerializeObject();
        self.SubmitStatusText(null);
        self.SubmitStatusClass(null);
        HsbDataService.SendContactForm(formData.Subject, formData.Message, formData.EmailAddress, formData.Name, function () {
            self.SubmitStatusText("Bericht succesvol verstuurd");
        }, error => {
                self.SubmitStatusText("Probleem bij versturen bericht: " + error);
                self.SubmitStatusClass("error");
        });
    }

    this.GetSerializeObject = function () {
        return {
            Name: self.Name(),
            EmailAddress: self.EmailAddress(),
            Subject: self.Subject(),
            Message: self.Message()
        };
    };
}