import React from 'react'

var hostAPI = window._hsb_sk_api;

class StoredQuerysWidget extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            //selectValue: this.props.storedQueries.length > 0 ? this.props.storedQueries[0].Id : null,
            //selectValue: null,
            selectValue: "",
            //storedQueries: this.props.storedQueries
            storedQueries: hostAPI.handlers.storedQueries()
        };

        hostAPI.handlers.updateSelection = (plainQueryObject, queryUrl) => {
            

            var selected = this.state.selectValue;
            var query = this.state.storedQueries.find(q => q.Query === queryUrl);

            console.log("updateSelection selected", selected);
            console.log("updateSelection query", query);

            if (selected && !query) {
                console.log("clear selection");
                this.setState({ storedQueries: this.state.storedQueries, selectValue: "" });
            }
        }
    }

    addQuery = () => {
        var name = prompt('Welke naam wil je aan deze zoekopdracht geven?');
        if (!name) return;
        var queryUrl = this.props.getQueryUrl();
        hostAPI.handlers.addQuery(name, queryUrl, newStoredQueries => {
            this.setState({
                storedQueries: newStoredQueries,
                selectValue: newStoredQueries.find(q => q.Name === name && q.Query === queryUrl).Id
            });
        });
    }

    removeQuery = () => {
        var id = this.state.selectValue;
        if (!confirm('Weet je zeker dat je deze zoekopdracht wilt verwijderen?')) return;
        hostAPI.handlers.removeQuery(id, newStoredQueries => {
            //this.setState({ storedQueries: newStoredQueries, selectValue: newStoredQueries.length > 0 ? newStoredQueries[0].Id : "" });
            this.setState({ storedQueries: newStoredQueries, selectValue: "" });
            this.props.performSearch("*");
        });
    }

    handleChange = e => {
        var selectedId = e.target.value;
        this.setState({ selectValue: selectedId ?? "" });
        var query = this.state.storedQueries.find(q => q.Id === selectedId);
        if (query) this.props.performSearch(query.Query);
    }

    render() {
        var options = this.state.storedQueries.map(o => <option key={o.Id} value={o.Id}>{o.Name}</option>)
        
        return (
            <div className="storedQueriesHolder">
                <div className="sk-select">
                    <select value={this.state.selectValue} onChange={this.handleChange}>
                        <option></option>
                        {options}
                    </select>
                </div>
                <a onClick={this.addQuery}>Bewaar zoekopdracht</a>
                {
                    !!this.state.selectValue &&
                    <a onClick={this.removeQuery}>Verwijder zoekopdracht</a>
                }
            </div>
        );
    }
}

export default StoredQuerysWidget;