import ko from 'knockout'
import {getTranscriptItems, transcriptWordToSourceWordsFactory, getHighlightWordIndex} from './getKaraokeText.js'

export default function BookPlayModel(bookData, siteData, readerSettings, screen, fromScanningLibrary) {
    const self = this;
    const PageStates = { PlayingVoiceAndFx: 1, FinishedPlayingVoiceAndFx: 2, PlayingAttentionSoundAndPic: 3, FinishedPlayingAttentionSoundAndPic: 4 };
    const _HSB_SubtitleHtml5Marker = "__HTML5*__";

    if (!readerSettings) readerSettings = {};

    let currentPageState = null;
    let lastStateChangeTimeStamp = 0, timeSinceLastStateChange = 0;

    let actionAfterBookFinish = "Menu";
    if (typeof (readerSettings.ActionAfterBookFinish) == "undefined") {
        actionAfterBookFinish = readerSettings.RepeatSingleBook ? "Repeat" : "Menu";
    } else actionAfterBookFinish = readerSettings.ActionAfterBookFinish;

    //Er is hier gebruik gemaakt van een statemachine. Uitleg over deze statemachine vind je hier: https://stately.ai/viz

    let pageIntervalTimer = null;
    let voiceKaraokeInterval = null;
    let transcriptItems;
    let voiceStartTime = (new Date()).getTime();

    let transcriptWordToSourceWords = () => null;

    const baseUploadPath = siteData.BaseUploadPath;

    let allAttentionFileItems = [];
    if (siteData.DefaultAttentionImages) allAttentionFileItems = allAttentionFileItems.concat(siteData.DefaultAttentionImages);
    if (siteData.DefaultAttentionSounds) allAttentionFileItems = allAttentionFileItems.concat(siteData.DefaultAttentionSounds);
    if (readerSettings.UploadedAttentionImages) allAttentionFileItems = allAttentionFileItems.concat(readerSettings.UploadedAttentionImages);
    if (readerSettings.UploadedAttentionSounds) allAttentionFileItems = allAttentionFileItems.concat(readerSettings.UploadedAttentionSounds);

    this.ReaderSettings = readerSettings;
    this.PageIndex = ko.observable(-1);

    this.ReaderSettingsFontSizeClass = readerSettings.FontSize ? readerSettings.FontSize.replace(" ","-") : "";
    function getReaderSettingsFormat() {        
        let format = {};
        if (readerSettings.TextColor) format.color = readerSettings.TextColor + (readerSettings.Karaoke ? '' : ' !important');
        if (readerSettings.Font) format.font = readerSettings.Font + ' !important';
        return format;
    }

    this.KaraokeDisplayWords = ko.observable();
    this.KaraokeCurrentWord = ko.observable({ min: -1, max: -1 });
    this.KaraokeColor = readerSettings.KaraokeColor || 'red';
    this.KaraokeTextDecoration = readerSettings.KaraokeUnderline ? 'underline' : '';
    this.TextColor = readerSettings.TextColor;
    this.IsKaraokeSpanActive = index => {
        const highlight = self.HighlightWordIndex();
        return index >= highlight.min && index <= highlight.max;
    }

    const formatBlock = getReaderSettingsFormat();

    this.DefaultTextFormatBlock = formatBlock;

    this.CurrentPage = ko.computed(function () { const index = self.PageIndex(); return index > 0 && index <= bookData.Pages.length ? bookData.Pages[index - 1] : null; });

    function backendArgbHexToRgbHex(backendArgb) {
        if (!backendArgb || backendArgb.length != 9) return "#ffffff";
        return "#" + backendArgb.substr(3);
    }
    this.ImageScaling = ko.computed(function () 
    { 
        const page = self.CurrentPage(); 
        if (!page) return null; 
        return page.AfbeeldingSchaling || bookData.DefaultPictureFrameScaling; 
    });

    this.BackgroundColor = ko.computed(function () {        
        if (readerSettings.PageColor) return readerSettings.PageColor; 
        const page = self.CurrentPage();
        return page ? backendArgbHexToRgbHex(page.Achtergrondkleur) : null;
    });

    this.AttentionImageUrl = ko.observable();

    function getFileUrl(fileItemId) {
        const fileItem = _(allAttentionFileItems).find(i => i.Id === fileItemId);
        if (!fileItem || !fileItem.Id) return null;
        return baseUploadPath + "/" + fileItem.Id + "." + fileItem.Ext;
    }

    this.IsLastPage = () => self.PageIndex() === bookData.Pages.length;
    this.IsFirstPage = () => self.PageIndex() === 1;

    this.ShowiOSStartButton = ko.observable(false);

    this.ClearTimer = function () {
        if (!pageIntervalTimer) return;
        clearInterval(pageIntervalTimer);
        pageIntervalTimer = null;
    }

    this.ExitBook = function () {
        self.ClearTimer();

        stopKaraokeInterval();

        _hsb_audiostop();

        if (fromScanningLibrary) {
            screen.BookPlayer(null);
            screen.ScanningLibrary(screen.ScanningLibraryTemp);
            screen.ScanningLibrary().ContinueAfterBook();
        } else {
            screen.NavigateTo(screen.BookLaunchFromPageNav || { page: 'home' });
        }
    }

    this.HasBrowseButton = !!readerSettings.BrowseButtonLocation;

    function removeEmptySpans(html) {
        return (html || '').replace(/\<span[\d\w\-\=\"\;\.\s\:]*\>\s*\<\/span\>/gi, '');
    }

    this.PageLineSpaceCssClass = ko.computed(function () {
        const ct = parseSubtitleContent();
        return "line_" + ct.lineSpacing;
    });
    
    function parseSubtitleContent() {
        let subContent = { isHtml5: false, lineSpacing: 0, html: '' };

        const page = self.CurrentPage();
        if (!page) return subContent;
        const initialContent = page.SubtitleHtml || "";

        if (initialContent.indexOf(_HSB_SubtitleHtml5Marker) > -1) {
            subContent.isHtml5 = true;
            const hsbStyleJson = initialContent.split(_HSB_SubtitleHtml5Marker)[0];
            let html = initialContent.split(_HSB_SubtitleHtml5Marker)[1];
            html = removeEmptySpans(html);
            subContent.html = "<div style='text-align:left;line-height:normal'>"+html+"</div>";
            try {
                const hsbStyle = JSON.parse(hsbStyleJson);
                subContent.lineSpacing = hsbStyle.line;
            } catch (exc) { }
        } else {
            subContent = { isHtml5: false, lineSpacing: null, html: initialContent };
        }

        return subContent;
    }

    this.PageIsHtml5SubCssClass = ko.computed(function () {
        const ct = parseSubtitleContent();
        return ct.isHtml5 ? "html5" : "flash";
    });

    this.CurrentText = ko.computed(function () {
        const ct = parseSubtitleContent();
        if (ct.html.indexOf('GEEN_TEKST') > -1) return null;
        return ct.isHtml5 ? ct.html : ConvertTextFormatTags(ct.html, 24);
    });

    this.CurrentImage = ko.computed(function () {
        const page = self.CurrentPage();
        if (!page) return {};
        return { url: page.ImageUrl, scaling: self.ImageScaling() };
    });

    function setPageState(newState) {
        lastStateChangeTimeStamp = new Date();
        timeSinceLastStateChange = 0;
        currentPageState = newState;
    }

    function isNeedTouchToPlayError(errorMessage) {
        // for now, make no attempt to differentiate the error, they are not standardized cross-browser.
        // instead, just generate the error once.
        const haveError = !!errorMessage;
        const haveShownTouchToPlayMessageAlready = !!window["_hsb_haveshown_needTouchToPlay"];
        return haveError && !haveShownTouchToPlayMessageAlready;        
    }

    function needTouchToPlay() {        
        self.ShowiOSStartButton(true);
        window["_hsb_audiofinishhandler"] = null;        
        window["_hsb_haveshown_needTouchToPlay"] = true;
    }

    function playAttentionSoundAndPic() {
        console.log('play attention sound and pic');
        if (readerSettings.SelectedAttentionImageId || readerSettings.SelectedAttentionSoundId) setPageState(PageStates.PlayingAttentionSoundAndPic);
        if (readerSettings.SelectedAttentionImageId) self.AttentionImageUrl(getFileUrl(readerSettings.SelectedAttentionImageId));
        if (readerSettings.SelectedAttentionSoundId) {
            //if (!checkAudioContextState()) return;
            _hsb_loadandplayaudio(getFileUrl(readerSettings.SelectedAttentionSoundId), function (error) {
                if (isNeedTouchToPlayError(error)) needTouchToPlay();
            }, readerSettings.AttentionSoundVolume);
        }
    }

    const attentionSoundDelay = readerSettings.AttentionSoundDelay || 500;    

    function onInterval() {
//TODO
        timeSinceLastStateChange = new Date() - lastStateChangeTimeStamp;
        /*
        if (timeSinceLastStateChange > FIVE_MINUTES) {
            console.log('Audio playback timeout, continuing with next page');
            currentPageState = PageStates.FinishedPlayingVoiceAndFx;
        }*/

        switch (currentPageState) {
            case PageStates.PlayingVoiceAndFx:

                break;
            case PageStates.FinishedPlayingVoiceAndFx:
                if (timeSinceLastStateChange > attentionSoundDelay) {
                    if (readerSettings.BrowseAutomatically) self.GotoNextPage();
                    else playAttentionSoundAndPic();
                }
                break;
            case PageStates.PlayingAttentionSoundAndPic:
                /*if (timeSinceLastStateChange > 3000) {
                    self.AttentionImageUrl(null);
                }*/
                break;
        }
    }

    pageIntervalTimer = setInterval(onInterval, 250)

    function allPageAudioFinished() {
        console.log('allPageAudioFinished');
        setPageState(PageStates.FinishedPlayingVoiceAndFx);
        stopKaraokeInterval();
    }

    this.HighlightWordIndex = ko.observable({min:-1,max:-1});
 
    function stopKaraokeInterval() {
        if (voiceKaraokeInterval) clearInterval(voiceKaraokeInterval);         
        voiceKaraokeInterval = null;
    }

    function onVoiceOverStartPlaying(transcriptItems) {
        stopKaraokeInterval();
        voiceStartTime = (new Date()).getTime();
        voiceKaraokeInterval = setInterval(() => {
            if (!transcriptItems) return;
            const highlightWordIndex = getHighlightWordIndex(transcriptItems, transcriptWordToSourceWords, voiceStartTime)
            self.HighlightWordIndex(highlightWordIndex);

        }, 50); // 50ms 
    }

    function showPage(index)
    {      
        console.log('showPage', index);         
        setPageState(null);

        self.AttentionImageUrl(null);
        _hsb_audiostop();
        self.PageIndex(index);
        
        const page = self.CurrentPage();
        if (!page) return;

        stopKaraokeInterval();

        if (readerSettings.Karaoke) {
            transcriptItems = getTranscriptItems(page.Transcript);            
            transcriptWordToSourceWords = transcriptWordToSourceWordsFactory(transcriptItems, page.SubtitleHtml, _HSB_SubtitleHtml5Marker, self.KaraokeDisplayWords);
            self.HighlightWordIndex(getHighlightWordIndex(transcriptItems, transcriptWordToSourceWords, voiceStartTime));
        }

        const fxAudio = readerSettings.DisableEffectSounds ? null : page.FXAudioUrl;
        const spokenAudio = page.SpokenAudioUrl;
        let audio1 = page.PlayFX_Before_Spoken ? fxAudio : spokenAudio;
        let audio2 = page.PlayFX_Before_Spoken ? spokenAudio : fxAudio;
        if (audio2 && !audio1) { audio1 = audio2; audio2 = null; }

        if (!audio1) return allPageAudioFinished();

        setPageState(PageStates.PlayingVoiceAndFx);
        _hsb_loadandplayaudio(audio1, async error1 => {
            if (isNeedTouchToPlayError(error1)) return needTouchToPlay();
                
            window["_hsb_haveshown_needTouchToPlay"] = true; // assume audio was succesfully played, so no need to show the message anymore
            
            if (!audio2) return allPageAudioFinished();

            setTimeout(() => {
                console.log('audio2 timeout finished');
                setPageState(PageStates.FinishedPlayingVoiceAndFx);
            }, await getMp3MetaDataDuration(audio2));

            _hsb_loadandplayaudio(audio2, error2 => {
                if (isNeedTouchToPlayError(error2)) return needTouchToPlay();
                console.log('audio2 play finished');

                allPageAudioFinished();
            }, null, page.PlayFX_Before_Spoken ? () => {
                onVoiceOverStartPlaying(transcriptItems);
            } : null);            
        }, null, page.PlayFX_Before_Spoken ? null : () => {
            onVoiceOverStartPlaying(transcriptItems);
        });
    }

    this.ShowCurrentPage = function () {
        self.ShowiOSStartButton(false);
        showPage(self.PageIndex());
    }

    function showNextBook(isFromScanningLibrary) {
        if (pageIntervalTimer) {
            clearInterval(pageIntervalTimer);
            pageIntervalTimer = null;
        }
        _hsb_audiostop();
        screen.BookPlayer(null);

        let item = null;

        if (isFromScanningLibrary) {
            const scanningLibrary = screen.ScanningLibraryTemp;
            const index = scanningLibrary.ScanIndex();
            scanningLibrary.ScanIndex(scanningLibrary.IsLastItem() ? 1 : index + 1);
            item = scanningLibrary.CurrentScanItem();
            screen.PlayBook(item.id, true);
        } else {
            const nextBook = screen.NextFromBookPlaylist(bookData.Id);
            if (nextBook == null) {
                self.ExitBook();
                return;
            }
            screen.PlayBook(nextBook.BookId, false);
        }
    }

    this.GotoNextPage = function ()
    {
        if (self.ShowiOSStartButton()) {
            self.ShowiOSStartButton(false);
            showPage(1);
            return;
        }

        if (currentPageState === PageStates.PlayingVoiceAndFx && readerSettings.MustWaitForAudioFinish) return;

        //if (!readerSettings.BrowseAutomatically && timeSinceLastStateChange < 1500) return; // wait 1.5 secs before advancing
        
        if (self.IsLastPage()) {

            switch (actionAfterBookFinish) {
                case "Repeat":
                    showPage(1);
                    break;
                case "NextBook":
                    showNextBook(fromScanningLibrary);
                    break;
                default:
                    self.ExitBook();
                    break;
            }
            
            return;
        }
        showPage(self.PageIndex() + 1);
    }

    this.GotoPreviousPage = function () {
        if (self.IsFirstPage()) return;

        if (currentPageState === PageStates.PlayingVoiceAndFx && readerSettings.MustWaitForAudioFinish) return;

        showPage(self.PageIndex() - 1);
    }

    this.Start = function (page) {
        if (page) showPage(parseInt(page, 10)); else showPage(1);
    }

    this.GotoLastPage = function () {
        showPage(bookData.Pages.length);
    }

    this.InputHandlers =
    {
        enablePointerSelect: !self.HasBrowseButton,

        pageUp: self.GotoPreviousPage,
        pageDown: self.GotoNextPage,
        left: self.GotoPreviousPage,
        right: self.GotoNextPage,

        home: self.ExitBook,
        end: self.GotoLastPage,
        escape: self.ExitBook, 
        enter: self.GotoNextPage,
        space: self.GotoNextPage,
        //pause: self.TogglePause,
        pointerSelect: self.GotoNextPage
    };
}