import ko from 'knockout'
import $ from 'jquery'
import SearchKit from './SearchKit'

ko.bindingHandlers.audioRecorder = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var options = valueAccessor();

        element.id = "audioRecorder";
        var iOS = _isIOS();

        var pipeParams = { size: { width: 300, height: 200 }, qualityurl: "avq/360p.xml", accountHash: "b6fb57f48997a122fffaa759892050e2", eid: "Jw2xgg", mrt: 10, ao: iOS ? 0 : 1 };
        pipeParams.payload = JSON.stringify({userId: options.userId, source: 'hsb'});

        var myRecorderObject = null;

        PipeSDK.insert(element.id, pipeParams, function (recorderObject) {
            myRecorderObject = recorderObject;
            myRecorderObject.onSaveOk = function (recorderId, streamName, streamDuration, cameraName, micName, audioCodec, videoCodec, fileType, videoId, audioOnly, location) {
                options.verwerkOpname(videoId);
                //https://eu1-addpipe.s3.eu-central-1.amazonaws.com/b6fb57f48997a122fffaa759892050e2/GVYKelxNdWfyZ9XwykZSulzsryb8YtMD.mp4
            }
            myRecorderObject.onVideoUploadSuccess = function (recorderId, filename, filetype, videoId, audioOnly, location) {
                options.verwerkOpname(videoId);
            }
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            if (myRecorderObject) {
                try {
                    myRecorderObject.remove();
                    myRecorderObject = null;
                } catch (exc) { }
            }            
        })
    }
}
    
ko.bindingHandlers.warnUnsavedChanges =
    {
        init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
            var options = valueAccessor();

            var screenModel = bindingContext.$root;
            screenModel.OnBeforeNavigate = function () {
                var onBeforeNavigate = options.onBeforeNavigate();
                if (onBeforeNavigate) return true;
                return confirm(options.message);
            }

            ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                screenModel.OnBeforeNavigate = null;
            })
        }
    }

ko.virtualElements.allowedBindings.warnUnsavedChanges = true;

ko.bindingHandlers.childEnvScroll = {
    init: function (element, valueAccessor) {

        var options = ko.unwrap(valueAccessor());

        var scanItemsHolder = document.getElementsByClassName('scanitems-holder')[0];
        scanItemsHolder.style.top = "0px";
        
        function updateButtons(newTop) {
            var currentTop = newTop === undefined ? scanItemsHolder.offsetTop : newTop;
            var totalListHeight = scanItemsHolder.offsetHeight;
            var windowHeight = window.innerHeight;

            var scrollDistance = Math.floor(windowHeight * 3.0 / 4.0);            

            var enableNext = ((-currentTop) + scrollDistance) < totalListHeight;
            options.enableNext(enableNext);
            //var enablePrevious = ((-currentTop) - scrollDistance) > (- scrollDistance);
            var enablePrevious = (-currentTop) > 0;
            options.enablePrevious(enablePrevious);
            console.log({ currentTop, scrollDistance, enablePrevious, newTop })
            //console.log({ calcTop: calcTop, top, totalHeight, parentHeight, enableNext, enablePrevious });
        }

        options.scrollhandlers({
            next: function () {
                if (!options.enableNext()) return;
                var currentTop = scanItemsHolder.offsetTop;
                var windowHeight = window.innerHeight;
                var scrollDistance = Math.floor(windowHeight * 3.0 / 4.0);  
                var newTop = currentTop - scrollDistance;
                scanItemsHolder.style.top = newTop + "px";
                updateButtons(newTop);
            },
            previous: function () {
                if (!options.enablePrevious()) return;                
                var currentTop = scanItemsHolder.offsetTop;
                var windowHeight = window.innerHeight;
                var scrollDistance = Math.floor(windowHeight * 3.0 / 4.0);
                var newTop = currentTop + scrollDistance;
                if (newTop > 0) newTop = 0;
                scanItemsHolder.style.top = newTop + "px";
                updateButtons(newTop);
            },
            update: function () {                
                updateButtons();
            },
            resetScroll: function () {
                scanItemsHolder.style.top = "0px";
                setTimeout(function () { updateButtons(); }, 500);
            },
            previousRow: function () {
                var currentTop = scanItemsHolder.offsetTop;
                var windowHeight = window.innerHeight;
                var scrollDistance = Math.floor(windowHeight * 3.0 / 8.0) * 2;
                var newTop = currentTop + scrollDistance;
                if (newTop > 0) newTop = 0;
                scanItemsHolder.style.top = newTop + "px";
                updateButtons(newTop);
                
            },
            nextRow: function () {
                if (!options.enableNext()) return;
                var currentTop = scanItemsHolder.offsetTop;
                var windowHeight = window.innerHeight;
                var scrollDistance = Math.floor(windowHeight * 3.0 / 8.0) * 2;  
                var newTop = currentTop - scrollDistance;
                scanItemsHolder.style.top = newTop + "px";
                updateButtons(newTop);
            }
        })

        function onResize(e) {
            updateButtons();                  
            options.updateItemSize(window.innerWidth);
        }

        window.addEventListener('resize', onResize);

        options.updateItemSize(window.innerWidth);
        //updateButtons();

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {            
            
            window.removeEventListener('resize', onResize);
        })
    }
}

ko.bindingHandlers.helpbutton = {
    init: function (element, valueAccessor) {

        var text = ko.unwrap(valueAccessor());

        element.className = "helpbutton";
        element.setAttribute("titleios", text);
        
        if (!_isIOS()) return;

        function onTouchStart(e) {
            //element.className = element.className.indexOf("touched") > -1 ? "helpbutton" : "helpbutton touched";
        }

        element.addEventListener('touchstart', onTouchStart, false);

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            if (!_isIOS()) return;
            element.removeEventListener('touchstart', onTouchStart, false);
        })
    }
}

ko.bindingHandlers.touchOrClick =
{
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var handler = ko.unwrap(valueAccessor());

        function onMouseUp(e) {
            
            if (e.button === 0) {
                e.preventDefault();
                e.stopPropagation();    
                handler(bindingContext.$data);
            }
        }

        function onTouchEnd(e) {
            e.preventDefault();
            e.stopPropagation();
            handler(bindingContext.$data);          
        }

        if (_isSupportedMobileDevice())
        {
            element.addEventListener('touchend', onTouchEnd, true);
        } else {
            element.addEventListener('mouseup', onMouseUp, true);
        }
      
        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {

            if (_isSupportedMobileDevice()) {
                element.removeEventListener('touchend', onTouchEnd, true);
            } else {
                element.removeEventListener('mouseup', onMouseUp, true);
            }
            
        });
    }
}

ko.bindingHandlers.inputHandlers =
{
    init: function (element, valueAccessor) {

        var oldIOS = _isIOS() && _iOSVersion() < 12;

        var options = ko.unwrap(valueAccessor());        

        //return;
        function onKeyUp(e) {
            var keyCode = oldIOS ? e.key : (e.code || e.key);
            console.log(keyCode);


            if (!keyCode) return;

            switch (keyCode) {
                case "Home":
                case 36:
                case 'UIKeyInputHome':                
                    if (options.home) options.home();
                    break;

                case "End":
                case 35:
                case 'UIKeyInputEnd':
                    if (options.end) options.end();
                    break;

                case "Esc":
                case "q":
                case 'UIKeyInputEscape':
                case 'Escape':
                    if (options.escape) options.escape();
                    break;

                case 'PageDown':
                    if (options.pageDown) options.pageDown();                                                         
                    break;

                case 'PageUp':
                    if (options.pageUp) options.pageUp();
                    break;

                case 'ArrowLeft':
                case 'UIKeyInputLeftArrow':
                case 'Left':
                    if (options.left) options.left();
                    break;

                case 'ArrowRight':
                case 'UIKeyInputRightArrow':
                case 'Right':
                    if (options.right) options.right();
                    break;

                case 'Enter':
                case 'NumpadEnter':
                    if (options.enter) options.enter();
                    break;

                case 'Space':
                case 'UIKeyInputDownArrow':
                case 'Spacebar':
                case ' ':
                    if (options.space) options.space();
                    break;

                case 'Pause':                                    
                    if (options.pause) options.pause();
                    break;
            }
        } 

        function onContextMenu(e) { e.preventDefault();}

        function onMouseUp(e) {
            switch (e.button) {
                case 0: // left
                    if (options.enablePointerSelect && options.pointerSelect) options.pointerSelect();
                    break;
                case 1:
                    // middle
                    break;
                case 2: //right
                    if (options.enablePointerSelect && options.rightClick) options.rightClick();
                    break;
            }            
        }

        function onTouchEnd(e) {
            if (options.enablePointerSelect && options.pointerSelect) options.pointerSelect();
            e.preventDefault();
        }

        document.addEventListener('keyup', onKeyUp, false);
        if (options.enablePointerSelect) {
            document.body.addEventListener('touchend', onTouchEnd, false);
            window.addEventListener('mouseup', onMouseUp, false);
        }        
        document.addEventListener('contextmenu', onContextMenu);

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {            
            document.removeEventListener('keyup', onKeyUp);
            if (options.enablePointerSelect) {
                document.body.removeEventListener('touchend', onTouchEnd, false);
                window.removeEventListener('mouseup', onMouseUp);
            }
            document.removeEventListener('contextmenu', onContextMenu);
        })
    }
}

ko.bindingHandlers.defaultFormatBook =
{
    init: function (element, valueAccessor) {
        var stdFmt = ko.unwrap(valueAccessor());

        if (!stdFmt) return;
        
        var inlineStyles = [];
        if (stdFmt.font) inlineStyles.push('font-family:' + stdFmt.font);
        if (stdFmt.size) inlineStyles.push('font-size:' + stdFmt.size + 'px');
        if (stdFmt.color) inlineStyles.push('color:' + stdFmt.color);
        
        var styleElement = _addStylesheetRules([
            '.textrow, .textrow p, .textrow span, .textrow .autowidth div {' + inlineStyles.join(';') + '}']);

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            document.head.removeChild(styleElement); styleElement = null;
        })

    }
}

ko.bindingHandlers.searchApp = {
    init: function (element, valueAccessor) {

        let options = ko.unwrap(valueAccessor());

        let hostAPI = window._hsb_sk_api;

        hostAPI.handlers = options.handlers

        hostAPI.render(element, options.skParams);

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            hostAPI.unrender();
        })
    }
}

ko.bindingHandlers.scan = {
    update: function (element, valueAccessor) {
        let options = valueAccessor();
        if (options.scanning == ScanningEnum.None) return;

        let scanItemModel = options.currentScanItem();
        if (!scanItemModel) return;
        let scanItemsHolder = document.getElementsByClassName('scanitems-holder')[0];
        let padding = 0;
        let borderSize = 10;
        let allScanItems = scanItemsHolder.querySelectorAll('li img');
        let scanItemElement = allScanItems[scanItemModel.index - 1];
        if (!scanItemElement) return;

        let holderTop = scanItemsHolder.offsetTop;

        function moveScanFrame() {
            element.style.top = (scanItemElement.offsetTop + holderTop - padding) + 'px';
            element.style.left = (scanItemElement.offsetLeft - padding) + 'px';
            element.style.width = (scanItemElement.offsetWidth - (borderSize * 2)) + 'px';
            element.style.height = (scanItemElement.offsetHeight - (borderSize * 2)) + 'px';
        }

        function scrollIntoView() {
            if(element.style.top == "") {
                moveScanFrame();
            }

            let hasScrolled = false;
            let parentHeight = scanItemsHolder.parentElement.offsetHeight;
            let scanItemBottom = scanItemElement.offsetTop + scanItemElement.offsetHeight;

            if (scanItemBottom > (parentHeight - holderTop) || (parentHeight + holderTop) < scanItemElement.offsetHeight ||
                 (holderTop < 0 && scanItemElement.offsetTop == 0) || scanItemElement.offsetTop > 0 && scanItemElement.offsetTop < scanItemBottom) {               
                options.pauseScanning(true);  

                let newScrollTop = scanItemBottom - scanItemElement.offsetHeight;

                scanItemsHolder.style.top = "-" + newScrollTop + "px";
                element.style.top = '0px';
                element.style.left = (scanItemElement.offsetLeft - padding) + 'px';

                setTimeout(function () {                    
                    options.pauseScanning(false);
                }, 500);

                hasScrolled = true;
            }

            if (scanItemModel.index < 2) {
                options.pauseScanning(true);
                scanItemsHolder.style.top = "0px";
                holderTop = 0;
                moveScanFrame(); 
                setTimeout(function () { options.pauseScanning(false); }, 1000);
                hasScrolled = true;
            }

            if (!hasScrolled) {
                holderTop = scanItemsHolder.offsetTop;
            }

            return hasScrolled;
        }

        if (!scrollIntoView()) {
            moveScanFrame();
        }
    }
}

ko.bindingHandlers.enterkey = {
    init: function (element, valueAccessor, allBindings, viewModel) {
        let callback = valueAccessor();
        $(element).keypress(function (event) {
            let keyCode = (event.which ? event.which : event.keyCode);
            if (keyCode === 13) {
                callback.call(viewModel);
                return false;
            }
            return true;
        });
    }
};

ko.bindingHandlers.validate = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        var allOptions = ko.unwrap(valueAccessor());

        var origClassname = element.className;
        var validationsObs = bindingContext.$data.Validations;
        var requiredObs = bindingContext.$data.Required;
        var subs = [];
        var validatorStates = {};
        if (!bindingContext.$data._filledInStates) bindingContext.$data._filledInStates = {};

        if (Array.isArray(allOptions[0])) {
            for (var i = 0; i < allOptions.length; i++) setupValidator(allOptions[i]);
        } else setupValidator(allOptions);

        function updateClass() {
            var hasinvalid = _(Object.keys(validatorStates)).find(v => !validatorStates[v]);
            element.className = hasinvalid ? origClassname + ' notvalid' : origClassname;
            element.title = hasinvalid;
        }

        function setupValidator(validatorOptions) {

            var validator = validatorOptions[0];
            var errorMessage = validatorOptions[1];

            validatorStates[errorMessage] = true;

            var isValid = validator;
            if (validator === "req") {
                isValid = v => !!(v && v.length > 0);
                bindingContext.$data._filledInStates[errorMessage] = isValid(allBindings.get('value')());
            } else if (typeof validator !== "function") isValid = v => v.match(new RegExp(validator));

            function updateValidationsObs(result)
            {
                var validations = validationsObs() || [];
                validatorStates[errorMessage] = result;
                if (result) {
                    validations = _(validations).filter(v => v !== errorMessage);
                } else {
                    if (validations.indexOf(errorMessage) < 0) validations.push(errorMessage);
                }
                updateClass();
                validationsObs(validations);
            }

            function updateRequiredObs(result)
            {
                if (_(bindingContext.$data._filledInStates).has(errorMessage)) bindingContext.$data._filledInStates[errorMessage] = result;
                var allReqFieldsAreFilledin = _(Object.keys(bindingContext.$data._filledInStates)).every(v => bindingContext.$data._filledInStates[v]);
                requiredObs(allReqFieldsAreFilledin);
            }

            function updateValidation(newVal, firstTime) {
                if (validator === 'req') {
                    var isFilled = isValid(newVal);
                    updateRequiredObs(isFilled);
                    updateValidationsObs(firstTime || isFilled);
                    return;
                } 
                if (!newVal) return updateValidationsObs(true);  // value is empty and field is not required for this validator, so approve
                var result = isValid(newVal);
                if (result && result.then) result.then(updateValidationsObs); // validator returns promise, wait for result
                else updateValidationsObs(result);    
            }

            subs.push(allBindings.get('value').subscribe(updateValidation));
            updateValidation(allBindings.get('value')(), true);
        }

        function removeValidator(validatorOptions) {
            validatorStates = null;
            var errorMessage = validatorOptions[1];

            var validations = validationsObs() || [];
            validations = _(validations).filter(v => v !== errorMessage);
            validationsObs(validations);

            bindingContext.$data._filledInStates = _(bindingContext.$data._filledInStates).omit([errorMessage]);
            var allReqFieldsAreFilledin = _(Object.keys(bindingContext.$data._filledInStates)).every(v => bindingContext.$data._filledInStates[v]);
            requiredObs(allReqFieldsAreFilledin);
        }


        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            //validationsObs(null);
            if (subs) for (var i = 0; i < subs.length; i++) subs[i].dispose();
            if (Array.isArray(allOptions[0])) {
                for (var o = 0; o < allOptions.length; o++) removeValidator(allOptions[o]);
            } else removeValidator(allOptions);
            subs = [];
        })
    }
}

ko.bindingHandlers.crossFadeImage = {
    init: function (element, valueAccessor) {
        var options = ko.unwrap(valueAccessor());

        var imageScreen = document.createElement("img");
        imageScreen.className = "screen";
        element.appendChild(imageScreen);
        var imageBuffer = document.createElement("img");
        imageBuffer.className = "buffer";
        element.appendChild(imageBuffer);


        function onImageLoad() {
            imageScreen.className = "screen fade";
            setTimeout(function () {
                imageScreen.src = imageBuffer.src;
                imageScreen.className = "screen";
            }, 1000);
        }
        imageBuffer.onload = onImageLoad;

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            element.removeChild(imageScreen);
            imageBuffer.onload = null;
            element.removeChild(imageBuffer);
        })
    },
    update: function (element, valueAccessor) {
        var options = ko.unwrap(valueAccessor());
        var scaleCss = options.scaling ? options.scaling.replace(/[^a-zA-Z0-9]/gi, "").toLowerCase() : "";

        element.className = "imagerow " + scaleCss;

        var imageScreen = element.querySelector(".screen");
        var imageBuffer = element.querySelector(".buffer");

        imageScreen.className = "screen";
        imageBuffer.className = "buffer";
        
        if (!options.url) return;

        if (options.url === imageScreen.src) return;

        imageBuffer.src = options.url;
    }
}

ko.bindingHandlers.fileUpload = {
    init: function(element, valueAccessor) {
        $(element).change(function() {
            valueAccessor()(element.files[0]);
        });
    },
    update: function(element, valueAccessor) {
        if (ko.unwrap(valueAccessor()) === null) {
            $(element).wrap('<form>').closest('form').get(0).reset();
            $(element).unwrap();
        }
    }
};

ko.bindingHandlers.router = {
    init: function (element, valueAccessor)
    {
        var navigateHandler = valueAccessor();        
        HashSubscribe(navigateHandler);
    }
}