import React from 'react'
import ReactDOM from 'react-dom';
import './searchtheme.css';
import qs from 'qs';

import {
    SearchkitManager,
    SearchkitProvider,
    SearchBox,
    Layout,
    LayoutBody,
    SideBar,
    Toggle,
    Select,
    RangeFilter,
    CheckboxFilter,
    LayoutResults,
    ActionBar,
    ActionBarRow,
    SortingSelector,
    GroupedSelectedFilters,
    ViewSwitcherHits,
    NoHits,
    RefinementListFilter,
    HitsStats,
    MenuFilter,
    Pagination,
    ResetFilters,
    RangeQuery,
    BoolMustNot
} from "searchkit";

import StoredQuerysWidget from './StoredQuerysWidget';
import BookHit from './BookHit';

var sk = null;
var hostAPI = window._hsb_sk_api;
var params = null;
var appDomElement = null;

class Application extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            storedQueries: this.props.storedQueries,
           // userId: params ? params.userId : null
        };
    }

    render() {
        console.log(params.lastReadByMeField)
        return (<div>
            <SearchkitProvider searchkit={sk}>
                <Layout>
                    <LayoutBody>
                        <SideBar>

                            <SearchBox searchOnChange translations={{ "searchbox.placeholder": "Zoek" }} placeholder="Zoeken op titels, inhoud, auteur" queryFields={["titel^2", "inhoud", "auteurs"]} queryOptions={{ "default_operator": "OR" }} prefixQueryFields={["titel^2", "inhoud"]} />

                            <CheckboxFilter filter={BoolMustNot(RangeQuery(params.lastReadByMeField, { lt: 40 }))} title="Niet recent gelezen" id="nietRecentGelezen4" label="Nooit of lang geleden gelezen" />

                            <RefinementListFilter id="themas" title="Thema" field="themas.raw" operator="OR" orderKey="_term" translations={{ "facets.view_more": "Toon meer thema's", "facets.view_less": "Klap themalijst in", "facets.view_all": "Toon alle thema's" }} size={10} />

                            <MenuFilter field={"niveauRubriek.raw"} title="Niveau" translations={{ "All": "Alle" }} id="Niveau" listComponent={Toggle} />


                            <MenuFilter field={"spreekStem.raw"} title="Spreekstem" id="spreekStem" translations={{ "All": "Alle" }} listComponent={Toggle} />

                            <MenuFilter field={"uitspraak.raw"} title="Uitspraak" id="uitspraak" translations={{ "All": "Alle" }} listComponent={Toggle} />

                            <MenuFilter field={"specialeGeluiden.raw"} title="Extra geluiden" translations={{ "All": "Alle" }} id="specialeGeluiden" listComponent={Toggle} />

                            <MenuFilter field={"muziek.raw"} title="Muziek" id="muziek" translations={{ "All": "Alle" }} listComponent={Toggle} />


                            <MenuFilter field={"auteurs.raw"} title="Auteurs" translations={{ "All": "Alle auteurs", "facets.view_more": "Bekijk meer auteurs", "facets.view_less": "Bekijk minder", "facets.view_all": "Bekijk alle auteurs" }} size={500} id="auteurs" orderKey="_term" listComponent={Select} />

                            <MenuFilter field={"uitgever.raw"} title="Uitgevers" translations={{ "All": "Alle uitgevers" }} id="uitgever" orderKey="_term" listComponent={Select} />

                            <MenuFilter field={"inspreker.keyword"} title="Inspreker" translations={{ "All": "Alle insprekers", "facets.view_more": "Bekijk meer insprekers", "facets.view_less": "Bekijk minder", "facets.view_all": "Bekijk alle insprekers" }} size={500} id="inspreker" orderKey="_term" listComponent={Select} />

                            <RangeFilter min={0} max={100} field="aantalBladzijden" id="aantalBladzijden" title="Aantal bladzijden" showHistogram />


                            <RangeFilter min={0} max={6} field="aantalRegelsPerScherm" id="aantalRegelsPerScherm" title="Aantal regels per scherm" showHistogram />


                            <RangeFilter min={0} max={params.leeftijdMaxValue} field="leeftijdVanaf" id="leeftijdVanaf" title="Leeftijd vanaf" showHistogram />

                            <RangeFilter min={0} max={params.leeftijdMaxValue} field="leeftijdTotEnMet" id="leeftijdTotEnMet" title="leeftijd tot en met" showHistogram />

                            <RangeFilter min={0} max={params.aantalKeerGelezenInAfgelopenMaandMaxValue + 10} field="aantalKeerGelezenInAfgelopenMaand" id="aantalKeerGelezenInAfgelopenMaand" title="Aantal lezers in afgelopen maand" showHistogram />

                            <RangeFilter min={1960} max={new Date().getFullYear()} field="uitgifteJaar" id="uitgifteJaar" title="Jaar van uitgifte" showHistogram />

                            <RangeFilter min={2011} max={new Date().getFullYear()} field="sitePublicatieJaar" id="sitePublicatieJaar" title="Toegevoegd aan Het Sprekend Boek" showHistogram />


                        </SideBar>
                        <LayoutResults>
                            <ActionBar>
                                <ActionBarRow>
                                    <HitsStats translations={{
                                        "hitstats.results_found": "{hitCount} resultaten gevonden"
                                    }}
                                    />
                                    <SortingSelector options={params.lastReadByMeField.length>11 ? [
                                        {
                                            label: "Sorteer op relevantie en populariteit", fields: [{ field: "_score", options: { order: "desc" } }, { field: "aantalKeerGelezenInAfgelopenMaand", options: { order: "desc" } }], order: "desc"
                                        },
                                        {
                                            label: "Sorteer op titel", field: "titel.keyword", order: "asc"
                                        },
                                        { label: "Sorteer op langst niet gelezen", field: params.lastReadByMeField, order: "desc" },
                                        { label: "Sorteer op onlangs gelezen", field: params.lastReadByMeField, order: "asc" },
                                        { label: "Sorteer op nieuwste publicaties", field: "sitePublicatieDatum", order: "desc" },
                                        { label: "Sorteer op oudste publicaties", field: "sitePublicatieDatum", order: "asc" }
                                    ] : [
                                            {
                                                label: "Sorteer op relevantie en populariteit", fields: [{ field: "_score", options: { order: "desc" } }, { field: "aantalKeerGelezenInAfgelopenMaand", options: { order: "desc" } }], order: "desc"
                                            },
                                            {
                                                label: "Sorteer op titel", field: "titel.keyword", order: "asc"
                                            },                                            
                                            { label: "Sorteer op nieuwste publicaties", field: "sitePublicatieDatum", order: "desc" },
                                            { label: "Sorteer op oudste publicaties", field: "sitePublicatieDatum", order: "asc" }
                                        ]}
                                    />
                                </ActionBarRow>
                                <ActionBarRow>
                                    <GroupedSelectedFilters />
                                    <ResetFilters translations={{ "reset.clear_all": "Verwijder alle filters" }} />
                                    {this.props.userId && <StoredQuerysWidget storedQueries={params.storedQueries} performSearch={performSearch} getQueryUrl={getQueryUrl} />}
                                </ActionBarRow>
                            </ActionBar>
                            <ViewSwitcherHits hitsPerPage={500}
                                sourceFilter={["titel", "thumbnailUrl", "auteurs", "uitgever"]}
                                hitComponents={[
                                    { key: "grid", title: "Grid", itemComponent: BookHit, defaultOption: true },
                                    { key: "list", title: "List", itemComponent: BookHit }
                                ]}
                                scrollTo="body"
                            />
                            <NoHits suggestionsField={"titel"} translations={{
                                "NoHits.NoResultsFound": "Geen resultaten voor {query}",
                                "NoHits.DidYouMean": "Zoek op {suggestion}",
                                "NoHits.SearchWithoutFilters": "Zoek op {query} zonder filters"
                            }}
                            />
                            <Pagination showNumbers translations={{ "pagination.previous": "Vorige", "pagination.next": "Volgende" }} />
                        </LayoutResults>
                    </LayoutBody>
                </Layout>
            </SearchkitProvider>
        </div>);
    }
}

const encodeObjUrl = obj => qs.stringify(obj, { encode: true, encodeValuesOnly: true })
const decodeObjString = str => qs.parse(str)
var removeResultsListener = null;

//"?Niveau[0]=Makkelijk&specialeGeluiden[0]=Ja&leeftijdTotEnMet[min]=0&leeftijdTotEnMet[max]=3&sort=titel.keyword_asc"

function getQueryUrl() {
    return encodeObjUrl(sk.state);
}

function performSearch(queryString) {
    if (queryString) {
        var queryState = decodeObjString(queryString.replace(/^\?/, ''));
        sk.accessors.setState(queryState);
    }
    return sk.performSearch();
}

function createSeachkitManager(initParams) {
    params = initParams;
    sk = new SearchkitManager(params.esServer || "http://es.edubox.nl/books", { timeout: 100000, useHistory: false, searchOnLoad: !params.initialQuery });

    if (params.initialQuery) sk.registrationCompleted.then(() => performSearch(params.initialQuery));
    if (!params.leeftijdMaxValue) params.leeftijdMaxValue = 99;

    sk.setQueryProcessor(function (plainQueryObject) {
        if (!hostAPI.handlers.queryProcessor) return plainQueryObject;
        try {
            var queryUrl = encodeObjUrl(sk.state);
            var result = hostAPI.handlers.queryProcessor(plainQueryObject, queryUrl);
            if (hostAPI.handlers.updateSelection) hostAPI.handlers.updateSelection(plainQueryObject, queryUrl);
            if (result) plainQueryObject = result;
        } finally {
            return plainQueryObject;
        }
    });

    removeResultsListener = sk.addResultsListener((results) => {
        if (hostAPI.handlers.onResults) hostAPI.handlers.onResults(results);
    });
}

hostAPI.unrender = function () {
    if (!appDomElement) return;
    ReactDOM.unmountComponentAtNode(appDomElement);
    appDomElement = null;
};

hostAPI.render = function (element, initParams) {
    if (appDomElement) return;
    var alreadyInitialized = !!sk;
    if (!sk) createSeachkitManager(initParams);

    appDomElement = element;

    var component = ReactDOM.render(<Application userId={initParams.userId} />, appDomElement);
    //component.setState({ userId:  });

    if (alreadyInitialized && initParams.initialQuery) {
        sk.registrationCompleted.then(() => performSearch(initParams.initialQuery));        
    }
};

export default Application;