import ko from 'knockout' 

export default function GiftDetailsModel(giftData, screen) {

    var self = this;
    this.RecipientFullName = ko.observable(giftData.RecipientFullName)
    this.RecipientEmailAddress = ko.observable(giftData.RecipientEmailAddress)
    this.RecipientPhoneNumber = ko.observable(giftData.RecipientPhoneNumber)
    this.Message = ko.observable(giftData.Message)

    this.Validations = ko.observable();
    this.Required = ko.observable();
    this.IsValid = ko.computed(function () { var vals = self.Validations(); return vals ? vals.length < 1 : false; });

    this.GetSerializeObject = function () {
        return {
            RecipientFullName: self.RecipientFullName(),
            RecipientEmailAddress: self.RecipientEmailAddress(),
            RecipientPhoneNumber: self.RecipientPhoneNumber(),
            Message: self.Message()
        };
    };
}