import ko from 'knockout' 
import UserDetailsModel from './UserDetailsModel'
import ScanningLibraryModel from './ScanningLibraryModel'
import BookPlayModel from './BookPlayModel'
import ReaderSettingsModel from './ReaderSettingsModel'
import PaymentFormModel from './PaymentFormModel'
import SearchAppModel from './SearchAppModel'
import ContactFormModel from './ContactFormModel'
import { EsGetBooks } from './booksquery'

export default function ScreenViewModel(siteData) {
    
    var self = this;

    function sanitizeRubriekNaam(naam) {
        var rubriekNaam = naam ? naam.replace(/[^\w\d]/gi, '-').toLowerCase() : null;
        return rubriekNaam;
    }

    var previousLocation = null;
    this.SiteData = siteData;
    this.CurrentQueryObject = null;
    
    this.ThemeImage = 'url("' + siteData.HuidigThema?.Thumbnail + '")';
    this.AllBooksIconUrl = siteData.AllBooksIconUrl;
    this.ScanningLibraryLaunchFromPageNav = null;
    this.ScanningLibraryTemp = null;
    this.BookLaunchFromPageNav = null;

    
    this.BookPlayer = ko.observable();
    this.ScanningLibrary = ko.observable();
    this.LoginPopup = ko.observable();
    this.PaymentForm = ko.observable();
    this.UserDetailsForm = ko.observable();
    this.SearchApp = ko.observable();
    this.SocialButtonsHtml = ko.observable();    
    this.KadoActivatieCode = ko.observable();
    this.Rubriek = ko.observable();
    this.Books = ko.observable();
    this.Categories = ko.observable();
    this.Nivo = ko.observable();
    this.SelectedPageContent = ko.observable();
    this.SelectedSiteSection = ko.observable();
    this.UserDetails = ko.observable(siteData.UserDetails ? new UserDetailsModel(siteData.UserDetails, null, self) : null)
    this.ReaderSettingsForm = ko.observable();
    this.ContactForm = ko.observable();

    this.GetRubriekLink = function (rubriek) {
        return '#nivo=' + self.Nivo() + '&rubriek=' + sanitizeRubriekNaam(rubriek.RubriekNaam);
    }

    this.GetRubriekLinkAllBooks = function () {
        return '#nivo=' + self.Nivo() + '&allbooks=true';
    }

    this.GetEenknopsLink = function () {
        if (self.SelectedSiteSection() === "favorieten") return '#page=eenknops&favs=true';
        if (self.SelectedSiteSection() === "thema") return '#page=eenknops&thema=true';
        var rubriek = self.Rubriek();
        return '#page=eenknops&nivo=' + self.Nivo() + (rubriek ? '&rubriek=' + sanitizeRubriekNaam(rubriek.RubriekNaam) : (self.Categories() ? '' : '&alleboeken=true'));
    }

    this.GetRubriekWeergaveNaam = function (nivo, rubriekUrlCode) {
        rubriekUrlCode = sanitizeRubriekNaam(rubriekUrlCode);
        var rubrieken = self.GetCategories(nivo);
        var rubriek = _(rubrieken).find(r => sanitizeRubriekNaam(r.RubriekNaam) === rubriekUrlCode);
        return rubriek ? rubriek.RubriekNaam : null;
    }

    this.GetCategories = function (nivo) {
        if (!nivo) return null;
        return nivo === "makkelijk" ? self.SiteData.SubRubrieken_Makkelijk : self.SiteData.SubRubrieken_Moeilijk;
    }

    this.PlayBook = function (id, fromScanningLibrary, page) {
        var isDemo = self.SelectedSiteSection() === "demo";

        var readerSettings = self.UserDetails() ? self.UserDetails().ReaderSettings() : null;
        HsbDataService.GetBookData(id, isDemo, bookData => {
            bookData.Id = id;
            var player = new BookPlayModel(bookData, siteData, readerSettings, self, fromScanningLibrary);
            self.BookPlayer(player);
            player.Start(page);
        }, error => {
                if (self.UserDetails()) {
                    SetSiteSectionNotFound('Abonnement is niet geactiveerd');
                    return;
                }
                ShowRequireLogin({ book: id }, {page: 'demo'});
        })
    }

    this.StartScanningLibrary = function (books, categories, nivo) {
        goFullScreen();

        var readerSettings = self.UserDetails() ? self.UserDetails().ReaderSettings() : null;

        var lib = new ScanningLibraryModel(books, categories, nivo, self, readerSettings);
        self.ScanningLibrary(lib);
        self.SelectedSiteSection("eenknops");
        lib.StartScanningFromTop();
    }

    this.IsLoggedIn = ko.computed(() => !!self.UserDetails());
    this.BodyCss = ko.computed(() => {
        var ieVersion = _detectIE();
        return [
            self.SelectedSiteSection(),
            self.IsLoggedIn() ? 'loggedin' : '',
            _isSupportedMobileDevice() ? 'mobile' : 'non-mobile',
            ieVersion && ieVersion <= 11 ? 'old-ie' : ''
        ].join(' ');
    });
    this.CustomPageTitle = ko.observable();

    this.Title = ko.computed(function () {
        var customTitle = self.CustomPageTitle();
        var page = self.SelectedPageContent();
        if (customTitle) return customTitle;                
        return page ? page.Titel : self.SelectedSiteSection();
    })    

    var sectionIsComp = sections => ko.computed(() => sections.indexOf(self.SelectedSiteSection()) > -1);
    var sectionIsNotComp = sections => ko.computed(() => sections.indexOf(self.SelectedSiteSection()) < 0);
    this.FooterIsVisible = sectionIsNotComp(["boek", "zoeken", "eenknops"]);
    this.CrumblePathIsVisible = sectionIsNotComp(["boek", "eenknops", "home"]);

    this.StartBetalenIsVisible = sectionIsComp(["startbetalen"]);
    this.SearchScreenIsVisible = sectionIsComp(["zoeken"]);
    this.IsGenericPageVisible = sectionIsComp(["dpage"]);
    this.RegistrationScreenIsVisible = sectionIsComp(["registreren"]);
    this.FrontPageIsVisible = sectionIsComp(["home"]);
    this.DemoScreenIsVisible = sectionIsComp(["demo"]);
    this.StartPageLinkIsVisible = sectionIsComp(["zoeken"]);
    this.CategoriesScreenIsVisible = sectionIsComp(["rubrieken"]);
    this.BooksScreenIsVisible = sectionIsComp(["boeken", "thema", "favorieten"]);
    this.IsHeaderVisible = sectionIsNotComp(["boek", "eenknops"]);
    this.IsPageVisible = sectionIsComp(["help", "tekstvak-onder-boekjes-homepage"]);
    this.IsContactPageVisible = sectionIsComp(["contact"]);
    this.IsMainContentVisible = sectionIsNotComp(["boek", "eenknops"]);

    this.Nag = ko.computed(function () {
        var headerIsVisible = self.IsHeaderVisible();

        var ieVersion = _detectIE();
        var iOSVersion = _iOSVersion();
  
        if (ieVersion && ieVersion < 11) return 'De Internet Explorer versie die je gebruikt is verouderd. Deze kunnen we helaas niet meer ondersteunen. Dit betekent dat het kan zijn dat niet alle functionaliteit van het Sprekend Boek goed werkt. Mocht je zeker willen zijn dat alles goed werkt, schakel dan over naar MS Edge, Chrome, Firefox of IE 11 of neem contact met ons op.';
        if (_isIOS() && iOSVersion < 11) return 'De iPad die je gebruikt is oud of gebruikt oude software (iOs of browser). Deze kunnen we helaas niet meer ondersteunen. Dit betekent dat het kan zijn dat niet alle functionaliteit van het Sprekend Boek goed werkt. Mocht je zeker willen zijn dat alles goed werkt, schakel dan over naar iOS 11 of hoger (en Safari) of neem contact met ons op.';
        return null;
    });

    this.ShowSignInWithMessage = function (message, onSignIn, OnSignInCancel) {
        self.LoginPopup(new UserDetailsModel({ SignInMessage: message, OnSignIn: onSignIn, OnSignInCancel: OnSignInCancel }, "signin", self));
    }

    this.ShowSignIn = function () {
        self.ShowSignInWithMessage(null, function () {
            self.NavigateTo({ page: 'home' });
        });
    }

    function ShowRequireLogin(nav, previousLocation) {
        self.ShowSignInWithMessage("U moet ingelogd zijn en een actief abonnement hebben om verder te gaan.", function onSignIn() {
            self.NavigateTo(nav);
        }, function onSignInCancel() {
            if (previousLocation) self.NavigateTo(previousLocation); else self.NavigateTo({ page: 'demo' });
        });
    }

    this.SignOut = function () {
        HsbDataService.Logout(function () {
            self.UserDetails(null);
            self.NavigateTo({ page: "home" });
        });        
    }

    this.ToggleFavoriteById = function (id, done) {
        var isFav = self.IsFavorite({BookId:id});
        if (isFav) HsbDataService.RemoveFavorite(id, favs => { self.UserDetails().FavorieteBoeken(favs); if (done) done(false); });
        else HsbDataService.AddFavorite(id, favs => { self.UserDetails().FavorieteBoeken(favs); if (done) done(true); });
    }

    this.ToggleFavorite = function (book) {
        self.ToggleFavoriteById(book.BookId);
    }

    this.IsFavorite = function (book) {
        var user = self.UserDetails();
        if (!user) return false;
        return _(user.FavorieteBoeken()).any(b => b.BookId === book.BookId);
    }

    this.StartPayment = function (activatieCode) {
        //self.UserDetails(new UserDetailsModel(userData, null, self));
        var paymentModel = new PaymentFormModel(activatieCode, self);
        self.PaymentForm(paymentModel);        
    }

    function getRubriekFromId(id) {
        var rubriek = _(self.SiteData.SubRubrieken_Moeilijk).find(r => r.Id === id);
        if (rubriek) return rubriek;
        return _(self.SiteData.SubRubrieken_Makkelijk).find(r => r.Id === id);
    }

    function SetSiteSectionNotFound(message) {
        var page = self.SiteData.RegularPages["niet-gevonden"] || { Inhoud: message||'Excuses, dit adres is onbekend.' };
        self.SelectedPageContent(page);
        self.SelectedSiteSection('dpage');
    }

    function mapOldSite(nav) {
        switch (nav.aspnetpage) {
            case 'archive':
                if (nav.favorites) return self.NavigateTo({ page: 'favorieten' });
                if (nav.demo) return self.NavigateTo({ page: 'demo' });
                if (!nav.cat) return;
                if (nav.cat === self.SiteData.EasyCategoryId) return self.NavigateTo({ nivo: 'makkelijk' });
                else if (nav.cat === self.SiteData.HardCategoryId) return self.NavigateTo({ nivo: 'moeilijk' });
                else if (nav.cat === self.SiteData.ThemeParentCategoryId) return self.NavigateTo({ page: 'thema' });
                else {
                    var rubriek = getRubriekFromId(nav.cat);
                    if (rubriek) return self.NavigateTo({ rubriek: sanitizeRubriekNaam(rubriek.RubriekNaam) });
                    else return SetSiteSectionNotFound();
                }
            case 'page':
                return self.NavigateTo(nav.eid ? { dpage: nav.eid } : { page: nav.pid });
            case 'contact':
                return self.NavigateTo({ page: 'contact' });
            case 'registreren':
                return self.NavigateTo({ page: nav.cadeau ? 'kadoschenken' : 'registreren', cadeauactivatie: nav.cadeauactivatie });
            case 'wachtwoordvergeten':
                return self.NavigateTo({ page: 'wachtwoordvergeten', pwresetcode: nav.pwresetcode, expires: nav.expires, uid: nav.uid });
            case 'zoek':
                return self.NavigateTo({ page: 'zoeken' });
            case 'index':
                return self.NavigateTo({ page: 'home' });
            case 'login':
                return self.NavigateTo({ page: 'login' });
            case 'scanninglibraryhtml5':
                return self.NavigateTo({ page: 'eenknops' });
            case 'bookhtml5':
                return self.NavigateTo({ book: nav.id });
            case 'book':
                return self.NavigateTo({ book: nav.id });
            case 'startbetaling':
                return self.NavigateTo({ page: 'startbetaling' });
            case 'betalingcompleet':
                return self.NavigateTo({ page: 'betalingcompleet', cadeauactivatie: nav.cadeauactivatie, transaction_id: nav.transaction_id });

        }
        return SetSiteSectionNotFound();
    }

    function SetUserDetailsForm(formType, giftCode, pwresetcode, expires) {
        var userDetails = self.UserDetails();
        var userData = userDetails ? userDetails.GetSerializeObject() : {};            
        var formModel = new UserDetailsModel(userData, formType, self, giftCode, pwresetcode, expires);
        self.UserDetailsForm(formModel);
    }

    this.OnBeforeNavigate = null;

    this.NavigateTo = function (nav) {
        console.log('navto', nav);
        var hash = EncodeHashVars(nav);
        history.pushState(null, null, '#' + hash);
        self.OnNavigate(nav);
    }

    this.TempBooksPlaylist = null;

    this.NextFromBookPlaylist = function (currentId) {
        var books = self.TempBooksPlaylist;
        if (!books) return null;
        var currentBook = _(books).find(b => b.BookId == currentId);

        var currentIndex = _(books).indexOf(currentBook);
        if (currentIndex<0) return null;
        currentIndex++;
        if (currentIndex >= books.length) currentIndex = 0;
        return books[currentIndex];
    }

    this.OnNavigate = function (nav) {
        console.log('onnav', nav);

        _hsb_audiostop();
        var activeBook = self.BookPlayer();
        if (activeBook) activeBook.ClearTimer();
        var activeLibrary = self.ScanningLibrary();
        if (activeLibrary) activeLibrary.ClearTimer();

        if (self.OnBeforeNavigate) {
            var canContinue = self.OnBeforeNavigate();
            if (!canContinue) {
                if (previousLocation) {
                    var previousHash = EncodeHashVars(previousLocation);
                    history.replaceState(null, null, '#' + previousHash);
                }
                return;
            }            
        }

        self.ScanningLibraryLaunchFromPageNav = null;
        self.ScanningLibraryTemp = null;
        self.BookLaunchFromPageNav = null;

        if (nav.aspnetpage) return mapOldSite(nav);

        var siteSection = nav.page;
        self.KadoActivatieCode(nav.cadeauactivatie);
        self.Nivo(nav.nivo);
        var rubriekNaam = nav.rubriek ? self.GetRubriekWeergaveNaam(nav.nivo, nav.rubriek) : null;

        self.LoginPopup(null);
        self.ReaderSettingsForm(null);
        self.ContactForm(null);
        self.Rubriek(null);
        self.PaymentForm(null);
        self.Books(null);
        self.SearchApp(null);
        self.UserDetailsForm(null);
        self.Categories(null);
        self.BookPlayer(null);
        self.ScanningLibrary(null);
        self.SelectedPageContent(null);
        self.CustomPageTitle(null);
        
        var demoPage = { page: 'demo' };




        var userDetails = self.UserDetails();
        var readerSettings = userDetails ? userDetails.ReaderSettings() : null;
        var pageContent = null;
        switch (siteSection) {
            case 'demo':
                pageContent = self.SiteData.RegularPages["prijzen-uitproberen"];
                EsGetBooks("Demonstratie", null, books => { self.Books(books); self.TempBooksPlaylist = books; });
                break;
            case 'zoeken':
                self.SearchApp(new SearchAppModel(self, siteData, userDetails));
                break;
            case 'leesinstellingen':
                self.CustomPageTitle("Instellingen");
                if (!userDetails) return ShowRequireLogin(nav, demoPage);                
                self.ReaderSettingsForm(new ReaderSettingsModel(readerSettings, userDetails.UserId(),
                    siteData.DefaultAttentionImages, siteData.DefaultAttentionSounds, siteData.BaseUploadPath, self));                    
                break;
            case 'favorieten':
                if (!userDetails) return ShowRequireLogin(nav, demoPage);
                self.Books(userDetails.FavorieteBoeken());
                self.TempBooksPlaylist = self.Books();
                break;
            case 'betalingcompleet':
                var updatePaymentCompletePage = function(pageCode, updatedUserDetails) {
                    var page = self.SiteData.RegularPages[pageCode];
                    
                    var isSuccess = (updatedUserDetails.SubscriptionType === "Kado") ? (updatedUserDetails.AccountStatus === "ENABLED") : updatedUserDetails.IsActieveAbonnee;
                    if (!isSuccess) {
                        page = self.SiteData.RegularPages["ideal-probleem"];
                        page.Inhoud += "\n  "+updatedUserDetails.LaatsteTransactieBoodschap;
                    }
                    self.SelectedPageContent(page);
                    self.SelectedSiteSection('dpage');
                    self.UserDetails(new UserDetailsModel(updatedUserDetails, null, self));
                }
                if (nav.cadeauactivatie) 
                    HsbDataService.GetUserDetailsFromGiftRedemptionCode(nav.cadeauactivatie, updatedUserDetails => {
                        updatePaymentCompletePage("cadeau-verstuurd", updatedUserDetails);
                    });
                else
                    HsbDataService.GetUserDetailsFromTransactionId(nav.transaction_id, updatedUserDetails => {
                        updatePaymentCompletePage("ideal-geslaagd", updatedUserDetails);
                    });
                break;
            case 'startbetaling':
                if (!userDetails) return ShowRequireLogin(nav, demoPage);
                self.StartPayment(nav.cadeauactivatie);
                break;
            case 'registreren':
                SetUserDetailsForm('register', nav.cadeauactivatie);
                break;
            case 'kadoschenken':
                pageContent = self.SiteData.RegularPages["cadeau-geven"];
                SetUserDetailsForm('gift', nav.cadeauactivatie);
                break;
            case 'tekstvak-onder-boekjes-homepage':
                pageContent = self.SiteData.RegularPages["tekstvak-onder-boekjes-homepage"];                
                break;
            case 'wachtwoordvergeten':
                pageContent = self.SiteData.RegularPages["wachtwoord-vergeten"];
                var pwForgetModel = new UserDetailsModel({ UserId: nav.uid }, 'resetpw', self, null, nav.pwresetcode, nav.expires);
                self.UserDetailsForm(pwForgetModel);                
                break;
            case 'wachtwoordwijzigen':
                pageContent = self.SiteData.RegularPages["nieuw-wachtwoord"];
                SetUserDetailsForm('changepw');
                break;
            case 'detailswijzigen':
                pageContent = self.SiteData.RegularPages["abonnementsgegevens-wijzigen"];
                SetUserDetailsForm('changedetails', nav.cadeauactivatie);
                break;
            case 'contact':
                pageContent = self.SiteData.RegularPages["colofon"];  
                self.SelectedPageContent(pageContent); 
                self.ContactForm(new ContactFormModel(self));
                break;
            case 'help':
                pageContent = self.SiteData.RegularPages["help"];
                break;
            case 'thema':
                EsGetBooks(siteData.HuidigThema?.RubriekNaam, null, books => { self.Books(books); self.TempBooksPlaylist = books; });
                break;
            case 'eenknops':
                if (!userDetails) return ShowRequireLogin(nav, demoPage);
                if (previousLocation && previousLocation.page !== 'eenknops' && !previousLocation.book) self.ScanningLibraryLaunchFromPageNav = previousLocation;
                if (nav.alleboeken) EsGetBooks(null, nav.nivo, books => self.StartScanningLibrary(books));
                if (nav.thema) EsGetBooks(siteData.HuidigThema?.RubriekNaam, null, books => self.StartScanningLibrary(books));
                else if (nav.favs) self.StartScanningLibrary(self.UserDetails().FavorieteBoeken());
                else if (rubriekNaam) EsGetBooks(rubriekNaam, nav.nivo, books => self.StartScanningLibrary(books));
                else self.StartScanningLibrary(null, self.GetCategories(nav.nivo), nav.nivo);                      
                break;
            case 'regularhomepage':
            case 'home':
            default:
                if (nav.book) {
                    if (previousLocation && previousLocation.page !== 'eenknops' && !previousLocation.book) self.BookLaunchFromPageNav = previousLocation;                    
                    self.PlayBook(nav.book, false, nav.page);
                    siteSection = 'boek';
                } else if (nav.dpage) {
                    siteSection = "dpage";
                    pageContent = self.SiteData.ExtraPages[nav.dpage];
                } else if (nav.page && nav.page !== "home" && nav.page !== "regularhomepage") pageContent = self.SiteData.RegularPages[nav.page];
                else if (nav.nivo) {
                    var rubrieken = self.GetCategories(nav.nivo);
                    siteSection = rubriekNaam ? "boeken" : "rubrieken";
                    if (rubriekNaam) {
                        var rubriek = _(rubrieken).find(r => sanitizeRubriekNaam(r.RubriekNaam) === nav.rubriek);
                        self.Rubriek(rubriek);
                        EsGetBooks(rubriekNaam, nav.nivo, books => { self.Books(books); self.TempBooksPlaylist = books; });
                    } else if (nav.allbooks) {
                        siteSection = "boeken";
                        EsGetBooks(null, nav.nivo, books => { self.Books(books); self.TempBooksPlaylist = books; });
                    } else {
                        self.Books(null);
                        self.Categories(rubrieken);
                    }
                } else if (!siteSection || siteSection === "home" || siteSection === "regularhomepage") {
                    if (readerSettings && readerSettings.SearchAsHomePage && siteSection !== "regularhomepage") {
                        self.SearchApp(new SearchAppModel(self, siteData, userDetails));
                        siteSection = "zoeken";
                    } else {
                        siteSection = "home";
                        pageContent = self.SiteData.RegularPages["welkomtekst-op-homepage"];
                    }                    
                }
                else return SetSiteSectionNotFound();
                break;
        }

        self.SelectedPageContent(pageContent);        
        self.SelectedSiteSection(siteSection);

        previousLocation = nav;
    }

    function init() {
        fetch("socialbuttons.html?v="+(new Date()).getTime()).then(r => r.text()).then(self.SocialButtonsHtml);
    }
    init();
}
