import GiftDetailsModel from './GiftDetailsModel'
import ko from 'knockout' 

export default function UserDetailsModel(userData, formType, screen, giftRedemptionCode, passwordResetCode, passwordResetExpiration) {
    var self = this;


    this.UserId = ko.observable(userData.UserId);

    this.FormType = ko.observable(formType);
    this.Validations = ko.observable();
    this.Required = ko.observable();
    this.SignInError = ko.observable();
    this.SubmitError = ko.observable();

    this.SubmitSuccess = ko.observable();

    this.SignInMessage = userData.SignInMessage || "Inloggen";

    this.GiftForm = ko.observable(false);

    this.LicenseDaysRemaining = userData.LicenseDaysRemaining;
    this.LicenseExpiresSoon = userData.LicenseDaysRemaining > 0 && userData.LicenseDaysRemaining < 60;

    this.IsValid = ko.computed(function () { var vals = self.Validations(); return vals ? vals.length < 1 : false; });

    this.IsGiftFormValid = ko.computed(function () {
        var giftForm = self.GiftForm();
        if (!giftForm) return false;
        var vals = giftForm.Validations();
        return vals ? vals.length < 1 : true;
    });

    this.EnableSubmit = ko.computed(function () {
        var enable = self.IsValid() && self.Required();
        var gift = self.GiftForm();
        if (!gift) return enable;
        return enable && gift.IsValid() && gift.Required();
    })
    this.IsDetailsVisible = ko.computed(() => self.FormType() === 'changedetails');

    this.IsRegistration = ko.computed(() => self.FormType() === "register");
    this.IsPasswordChange = ko.computed(() => self.FormType() === "changepassword");
    this.IsDetailsChange = ko.computed(() => self.FormType() === "changedetails");

    
    this.IsPasswordResetRequest = ko.computed(() => self.FormType() === "resetpw" && !passwordResetCode);
    this.IsPasswordResetConfirm = ko.computed(() => self.FormType() === "resetpw" && passwordResetCode);
    this.IsPasswordResetCodeValid = ko.observable(false);

    this.IsGiftButtonVisible = ko.computed(() => self.FormType() !== "resetpw" && !self.GiftForm());

    if (self.FormType() === "resetpw" && passwordResetCode) {
        HsbDataService.CheckPasswordResetCode(
            passwordResetCode,
            self.UserId(),
            passwordResetExpiration,
            function (isCorrect) {
                self.IsPasswordResetCodeValid(isCorrect);
                if (!isCorrect) self.SubmitError("De wachtwoord-reset bevestigingslink is oud of incorrect. Als u meerdere malen een wachtwoord reset heeft aangevraagd voor hetzelfde account, gebruik dan alleen de link in de laatst ontvangen email. Controleer anders aub of de link in de email correct is ingevoerd, of neem contact op.");
                //self.SubmitSuccess('Als deze gebruikersnaam in ons systeem is gevonden, ontvangt u op het geregistreerde email adres een bericht met een link waarmee u uw wachtwoord kunt resetten.');
            },
            error => self.SubmitError(error));
    }

    this.ShowGiftForm = function () {
        self.GiftForm(new GiftDetailsModel({}, screen));
        self.FormType("gift");
    };
    if (formType === "gift") self.ShowGiftForm();

    this.ChangePassword = () => self.FormType("changepassword");

    this.SubmitResetPasswordRequest = function () {
        self.SubmitSuccess(null);
        self.SubmitError(null);
        HsbDataService.StartPasswordResetRequest(
            self.UserName(),
            function (isSuccess) {

                if (isSuccess) self.SubmitSuccess('Als deze gebruikersnaam in ons systeem is gevonden, ontvangt u op het geregistreerde email adres een bericht met een link waarmee u uw wachtwoord kunt resetten.');
                else self.SubmitError('Er ging iets mis bij het versturen van het verzoek. Neem aub contact op met beheer (zie contact pagina).');
            },
            error => self.SubmitError(error));
    }

    this.SubmitResetPasswordConfirm = function () {
        self.SubmitSuccess(null);
        self.SubmitError(null);
        HsbDataService.ResetPasswordConfirm(
            self.NewPassword(),
            passwordResetCode,
            self.UserId(),
            passwordResetExpiration,
            function () {
                self.SubmitSuccess('Wachtwoord opnieuw ingesteld.');
            },
            error => self.SubmitError(error));
    }

    this.SubmitChangePassword = function () {
        self.SubmitSuccess(null);
        self.SubmitError(null);
        var userDetails = self.GetSerializeObject();
        HsbDataService.StoreUserInfoChanges(userDetails, function () {
            self.SubmitSuccess('Wachtwoord aangepast.');
        }, error => self.SubmitError(error));
    }

    this.SubmitRegistration = function () {
        self.SubmitSuccess(null);
        self.SubmitError(null);
        var userDetails = self.GetSerializeObject();
        HsbDataService.RegisterNewUser(userDetails, giftRedemptionCode||"", userData => {
            screen.UserDetails(new UserDetailsModel(userData, null, screen));
            if (giftRedemptionCode) return screen.NavigateTo({ page: "welkom" });
            else {
                //screen.UserDetailsForm(null);                
                //screen.StartPayment(null);
                screen.NavigateTo({ page: 'startbetaling' });
            }
        }, error => self.SubmitError(error));
    }

    this.SubmitChanges = function () {
        self.SubmitSuccess(null);
        self.SubmitError(null);
        var userDetails = self.GetSerializeObject();
        HsbDataService.StoreUserInfoChanges(userDetails, userData => {
            screen.UserDetails(new UserDetailsModel(userData, null, screen));
            self.SubmitSuccess('Wijzigingen opgeslagen.');
        }, error => self.SubmitError(error));
    }

    this.SubmitGift = function () {
        self.SubmitSuccess(null);
        self.SubmitError(null);
        var giftDetails = self.GiftForm().GetSerializeObject();
        var userDetails = self.GetSerializeObject();
        HsbDataService.SendGift(giftDetails, userDetails, giftStatus => {
            screen.UserDetails(new UserDetailsModel(giftStatus.UserDetails, null, screen, null));
            //screen.StartPayment(giftStatus.ActivatieId);
            screen.NavigateTo({ page: 'startbetaling', cadeauactivatie: giftStatus.ActivatieId });
        }, error => self.SubmitError(error));
    }

    this.SignInSubmit = function () {
        if (!self.Password()) {
            self.SignInError("Wachtwoord vereist");
            return;
        }
        HsbDataService.Login(self.UserName(), self.Password(), self.RememberMe(), u => {
            screen.UserDetails(new UserDetailsModel(u, null, screen));
            screen.LoginPopup(null);
            if (userData && userData.OnSignIn) userData.OnSignIn();
        }, error => self.SignInError(error));
    }

    this.CloseLoginPopup = () => {
        if (userData.OnSignInCancel) userData.OnSignInCancel();
        screen.LoginPopup(null);
    }


    this.UserName = ko.observable(userData.UserName);
    this.UserNameMessage = ko.observable();
    this.CheckName = newVal => {
        var promise = { resultHandler: null };
        promise.then = function (clientResultHandler) { promise.resultHandler = clientResultHandler; }
        HsbDataService.CheckNameTaken(newVal, isTaken => promise.resultHandler ? promise.resultHandler(!isTaken) : null);
        return promise;
    }
    this.NewPassword = ko.observable(userData.NewPassword);
    this.ConfirmNewPassword = ko.observable();

    this.Password = ko.observable(); // for login
    this.RememberMe = ko.observable(false); // for login


    this.EmailAddress = ko.observable(userData.EmailAddress)
    this.IsActieveAbonnee = ko.observable(userData.IsActieveAbonnee);
    this.FullName = ko.observable(userData.FullName)
    this.SchoolName = ko.observable(userData.SchoolName)
    this.PhoneNumber = ko.observable(userData.PhoneNumber)
    this.Address = ko.observable(userData.Address)
    this.City = ko.observable(userData.City)
    this.ZipCode = ko.observable(userData.ZipCode)
    this.TermsAccepted = ko.observable(false);

    this.PaymentMethod = ko.observable(userData.PaymentMethod)

    this.LastReadByMeMaxValue = userData.LastReadByMeMaxValue;
    this.KadosGegevenDoorMij = ko.observable(userData.KadosGegevenDoorMij)
    this.SubscriptionType = ko.observable(userData.SubscriptionType)
    this.SubscriptionStatus = ko.observable(userData.SubscriptionStatus)
    this.SubscriptionStarts = ko.observable(userData.SubscriptionStarts)
    this.SubscriptionEnds = ko.observable(userData.SubscriptionEnds)
    this.IsRenewal = ko.observable(userData.IsRenewal)

    this.StoredSearchQueries = ko.observable(userData.StoredSearchQueries)
    
    this.FavorieteBoeken = ko.observable(userData.FavorieteBoeken);
    this.PreviewThumbUrls_Favorieten = ko.computed(function () {
        var favs = self.FavorieteBoeken();
        if (!favs) return [];
        var twoBooks = _(favs).take(2);
        return twoBooks.map(function (book) { return book.CoverThumburl; });
    });

    var defaultReaderSettings = { RepeatSingleBook: true, AttentionSoundDelay: 1000, IsNewUser: true };
    var readerSettings = null;
    try {
        readerSettings = JSON.parse(userData.ReaderSettingsJSON);
    } catch (exc) { }
    this.ReaderSettings = ko.observable(readerSettings || defaultReaderSettings);

    this.LaatsteIdealTransactieId = ko.observable(userData.LaatsteIdealTransactieId)
    this.LaatsteTransactieBoodschap = ko.observable(userData.LaatsteTransactieBoodschap)
    this.AccountStatus = ko.observable(userData.AccountStatus)
    this.GebruikersRol = ko.observable(userData.GebruikersRol)

    this.ArePasswordsEqual = function (v) {
        var pw1 = self.NewPassword();
        var pw2 = self.ConfirmNewPassword();
        
        if (!pw1 || !pw2) return true;

        return pw1 === pw2;
    }

    this.NewPassword.subscribe(function () { self.ConfirmNewPassword.valueHasMutated(); });

    this.GetSerializeObject = function () {
        return {
            UserName: self.UserName(),
            NewPassword: self.NewPassword(),
            EmailAddress: self.EmailAddress(),
            FullName: self.FullName(),
            SchoolName: self.SchoolName(),
            PhoneNumber: self.PhoneNumber(),
            Address: self.Address(),
            City: self.City(),
            ZipCode: self.ZipCode(),

            SubscriptionType: self.SubscriptionType(),
            SubscriptionStatus: self.SubscriptionStatus(),
            SubscriptionStarts: self.SubscriptionStarts(),
            SubscriptionEnds: self.SubscriptionEnds(),
        };
    };
}
