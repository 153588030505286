function createCharacterMapping(sourceText, destText) {
    const dmp = new diff_match_patch();
    const diffs = dmp.diff_main(sourceText.toLowerCase(), destText.toLowerCase());

    let mapping = [];

    let sourceIndex = 0;
    let transcriptIndex = 0;

    diffs.forEach(d => {
        const runLength = d[1].length;
        for (let i = 0; i < runLength; i++) {
            const changeType = d[0];
            switch (changeType) {
                case 0: // match                            
                    if (mapping[transcriptIndex]) mapping[transcriptIndex].push(sourceIndex); else mapping[transcriptIndex] = [sourceIndex];
                    sourceIndex++;
                    transcriptIndex++;
                    break;
                case 1: // delete (transcript chars not in source)
                    if (mapping[transcriptIndex]) mapping[transcriptIndex].push(-1); else mapping[transcriptIndex] = [-1];
                    transcriptIndex++;
                    break;
                case -1: // add (source chars not in transcript)
                    sourceIndex++;
                    break;
            }
        }
    });

    return mapping;
}

function createWordMapping(characterMapping, transcriptWords) {
    let wordMapping = [];
    let transcriptIndex = 0;
    
    transcriptWords.forEach((wordItem, transcriptWordIndex) => {

        let wordCharIndexes = [];

        const skipTo = Math.min(transcriptIndex + wordItem.word.length, characterMapping.length - 1);

        while (transcriptIndex < skipTo) {
            const mapping = characterMapping[transcriptIndex];
            for (const element of mapping) wordCharIndexes.push(element);                    
            transcriptIndex++;
        }
        transcriptIndex++;

        if (wordCharIndexes.length > 0) {
            let minWordCharIndex = -1;
            let maxWordCharIndex = -1;

            for (const element of wordCharIndexes) {
                if (element < 0) continue;
                if (minWordCharIndex == -1 || element < minWordCharIndex) minWordCharIndex = element;
                if (maxWordCharIndex == -1 || element > maxWordCharIndex) maxWordCharIndex = element;
            }

            wordMapping[transcriptWordIndex] = { min: minWordCharIndex, max: maxWordCharIndex, wordItem: wordItem };
        }

    });
    return wordMapping;
}

function getSourceWord(minWordCharIndex, maxWordCharIndex, existingScreenWords, existingScreenText) {
    const words = existingScreenWords.map((w, i) => ({
        isSelected: w.startIndex <= maxWordCharIndex && w.endIndex >= minWordCharIndex,

        wordIndex: i,
        word: existingScreenWords[i],
        minCharIndex: w.startIndex,
        maxCharIndex: w.endIndex
    })).filter(w => w.isSelected);

    if (words.length < 1) return { indexes: [] };

    let minCharIndex = Number.MAX_VALUE;
    let maxCharIndex = 0;
    for (const element of words) {
        if (element.minCharIndex < minCharIndex) minCharIndex = element.minCharIndex;
        if (element.maxCharIndex > maxCharIndex) maxCharIndex = element.maxCharIndex;
    }

    const result = ({
        indexes: words.map(w => w.wordIndex),
        word: words.map(w => {
            const word = existingScreenWords[w.wordIndex];
            return existingScreenText.slice(word.startIndex, word.endIndex + 1);
        }).join(' ')
    });
    return result;
}

function getExistingScreenWords(existingScreenText) {
    const words = existingScreenText.split(' ');
        
    let existingScreenWords = [];
    let sourceIndex;

    for (sourceIndex = 0; sourceIndex < existingScreenText.length; sourceIndex++) {
        if (sourceIndex == 0 || existingScreenText[sourceIndex] == ' ') {
            if (existingScreenWords.length > 0) {
                let previousWord = existingScreenWords[existingScreenWords.length-1];
                previousWord.endIndex = sourceIndex - 1;
            }
            let word = words[existingScreenWords.length];
            if (existingScreenWords.length >= words.length) console.error('false index existingScreenWords', existingScreenWords.length, words.length);
            const plain = word.replace(/[^\w]/g, '').toLowerCase();
            existingScreenWords.push({
                startIndex: sourceIndex + (sourceIndex == 0 ? 0 : 1),
                rich: word.replace(/\n/g, '<br/>') + " ",
                plain: plain
            });
        }
    }
    if (existingScreenWords.length > 0) {
        let lastWord = existingScreenWords[existingScreenWords.length - 1];
        lastWord.endIndex = sourceIndex - 1;
    }
    return existingScreenWords;
}

export function getHighlightWordIndex(transcriptItems, transcriptWordToSourceWords, voiceStartTime) {
    const current_time_ms = (new Date()).getTime() - voiceStartTime;
    const transcriptIndex = transcriptItems.findIndex(w => current_time_ms >= w.start && current_time_ms <= w.end);
    let highlightWordIndex = { min: -1, max: -1 };
    if (transcriptIndex < 0) return highlightWordIndex;

    const sourceWords = transcriptWordToSourceWords(transcriptIndex);
    if (sourceWords.length > 0)
    {
        let minIndex = -1;
        let maxIndex = -1;
        for (const element of sourceWords) {
            if (minIndex == -1 || element < minIndex) minIndex = element;
            if (maxIndex == -1 || element > maxIndex) maxIndex = element;
        }
        highlightWordIndex = { min: minIndex, max: maxIndex };
    }

    return highlightWordIndex;
}

export function getTranscriptItems(pageTranscript) {
    const transcriptLines = pageTranscript ? pageTranscript.split('\n') : [];
    const regexTiming = /(\d+)\:(\d+) (.*)/;
    const transcriptItems = transcriptLines.map(l => {
        const values = l.match(regexTiming);
        return { start: parseInt(values[1], 10), end: parseInt(values[1], 10) + parseInt(values[2], 10), word: values[3]};
    })
    return transcriptItems;
}

export function transcriptWordToSourceWordsFactory(transcriptItems, pageSubtitleHtml, _HSB_SubtitleHtml5Marker, karaokeDisplayWordsObservable) {
    //const subtitleHtml = page.SubtitleHtml || '';
    const subtitleHtml = pageSubtitleHtml || '';
    const isHtml5Source = subtitleHtml.indexOf(_HSB_SubtitleHtml5Marker) > -1;
    let karaokeHtml = '';
    if (subtitleHtml.indexOf('GEEN_TEKST') < 0)        
        karaokeHtml = isHtml5Source ? subtitleHtml.split(_HSB_SubtitleHtml5Marker)[1] : subtitleHtml;

    const hasParagraphCloseTags = karaokeHtml.toLowerCase().indexOf('</p>') > -1;

    const endOfLineRegex = (isHtml5Source && !hasParagraphCloseTags) ? /\<\/div\>/ig : /\<\/p\>/ig;

    const karaokeDisplayText = karaokeHtml
        .replace(endOfLineRegex, '<br/>')
        .replace(/\<br\s*\/?\>/ig, '<br/>')
        .replace(/\<[a-z]+(\s\w+\=\"[^\"]*\")*\>/ig, '')
        .replace(/\<\/\w+\>/ig, '')
        
        .replace(/&apos;/ig, "'")
        .replace(/&nbsp;/ig, " ")

        .replace(/\s+/ig, ' ')
        .replace(/\<br\/?\>/ig, '\n ').trim();

    const karaokeDisplayWords = getExistingScreenWords(karaokeDisplayText);

    
    karaokeDisplayWordsObservable(karaokeDisplayWords);

    const transcriptText = transcriptItems.map(w => w.word).join(' ');

    const characterMapping = createCharacterMapping(karaokeDisplayText, transcriptText);
    const wordMapping = createWordMapping(characterMapping, transcriptItems);

    return (transcriptIndex =>
    {
        const transcriptWord = wordMapping[transcriptIndex];
        if (!transcriptWord) return [];
        const sourceWord = getSourceWord(transcriptWord.min, transcriptWord.max, karaokeDisplayWords, karaokeDisplayText);
        return sourceWord.indexes;
    });
}