import ko from "knockout";
import { EsGetBooks } from "./booksquery";

export default function ScanningLibraryModel(
    initBooks,
    initCategories,
    initLevel,
    screen,
    readerSettings
) {
    let self = this;

    this.ScanItems = ko.observable([]);
    this.ScanIndex = ko.observable(0);
    this.IsLastItem = () => self.ScanIndex() === self.ScanItems()?.length;
    this.IsFirstItem = () => self.ScanIndex() === 1;
    this.CurrentScanItem = ko.computed(function () {
        let index = self.ScanIndex();
        return index > 0 && index <= self.ScanItems().length
            ? self.ScanItems()[index - 1]
            : null;
    });

    this.Scanning = 
        readerSettings && !readerSettings.IsNewUser
            ? readerSettings.Scanning === undefined
                ? true
                : readerSettings.Scanning
            : false;

    this.EnableScrollButtons =
        self.Scanning == ScanningEnum.None &&
        readerSettings &&
        readerSettings.EnableScrollButtons;

    this.ScanningIsPaused = false;

    this.PreviousPage = function () {
        let handlers = self.Scrollhandlers();
        if (self.Scanning == ScanningEnum.Auto) {
            handlers.previous();
        } else {
            handlers.previousRow();
        }

        let index = self.ScanIndex();
        let pageUpIndex =
            self.Scanning == ScanningEnum.Auto
                ? parseInt(index - parseInt(self.NumberOfScanItemColumns * 2))
                : parseInt(index - parseInt(self.NumberOfScanItemColumns));

        self.ScanIndex(pageUpIndex <= 1 ? 1 : pageUpIndex);
        if (self.Scanning == ScanningEnum.Auto) {
            self.playTitleAudio();
        }
    };

    this.NextPage = function () {
        let handlers = self.Scrollhandlers();
        if (self.Scanning == ScanningEnum.Auto) {
            handlers.next();
        } else {
            handlers.nextRow();
        }

        let index = self.ScanIndex();
        let pageDownIndex =
            self.Scanning == ScanningEnum.Auto
                ? parseInt(index + parseInt(self.NumberOfScanItemColumns * 2))
                : parseInt(index + parseInt(self.NumberOfScanItemColumns));

        self.ScanIndex(
            self.IsLastItem() || pageDownIndex > self.ScanItems().length
                ? self.ScanItems().length
                : pageDownIndex
        );

        if (self.Scanning != ScanningEnum.None) {
            self.playTitleAudio();
        }
    };

    this.Scrollhandlers = ko.observable({
        previous: function () {},
        next: function () {},
        update: function () {},
        resetScroll: function () {},
        previousRow: function () {},
        nextRow: function () {}
    });

    this.EnablePreviousPage = ko.observable(false);
    this.EnableNextPage = ko.observable(false);

    this.IsScanning = self.Scanning != ScanningEnum.None;

    this.ModeCssClass =
        self.Scanning != ScanningEnum.None
            ? "scan"
            : self.EnableScrollButtons
            ? "buttonscroll"
            : "swipescroll";

    let numberOfScanItemColumns =
        readerSettings && readerSettings.NumberOfScanItemColumns;
    this.NumberOfScanItemColumns = numberOfScanItemColumns || 5;

    this.ItemSize = ko.observable(300);
    let interval = null;

    this.UpdateItemSize = function (windowWidth) {
        let widthRatio = self.EnableScrollButtons ? 0.9 : 1.0;
        let itemSize = (0.97 * widthRatio * windowWidth) / self.NumberOfScanItemColumns;
        self.ItemSize(Math.floor(itemSize));
    };

    this.ClearTimer = function () {
        if (!interval) return;
        clearInterval(interval);
        interval = null;
    };

    this.IsScanningActive = ko.observable(false);
    this.IsScanningActive.subscribe(active => {
        if (self.Scanning == ScanningEnum.Auto) {
            if (!active) {
                self.ClearTimer();
            } else {
                let scanTime = readerSettings
                    ? (readerSettings.ScanningInterval > 8
                          ? 2
                          : readerSettings.ScanningInterval) || 2
                    : 2;
                if (!interval)
                    interval = setInterval(function () {
                        if (!self.ScanningIsPaused) self.NextScanItem();
                    }, scanTime * 1000);
            }
        }
    });

    this.PreviousScanItem = function () {
        if (self.Scanning == ScanningEnum.None) return;
        if (self.EnableScrollButtons) {
            self.PreviousPage();
        } else {
            let index = self.ScanIndex();
            self.ScanIndex(self.IsFirstItem() ? 1 : index - 1);

            if (parseInt(index % self.NumberOfScanItemColumns) == 1) {
                self.Scrollhandlers().previousRow();
            }
            self.playTitleAudio();
        }
    };

    this.NextScanItem = function () {
        if (self.Scanning == ScanningEnum.None) return;
        if (self.EnableScrollButtons) {
            self.NextPage();
        } else {
            let index = self.ScanIndex();
            self.ScanIndex(self.IsLastItem() ? 1 : index + 1);

            self.playTitleAudio();
        }
    };

    this.playTitleAudio = function (bookId) {
        if (!readerSettings.ReadBookTitles || !initBooks || !initBooks.length) {
            self.IsScanningActive(true);
            return;
        }

        let book = bookId ? initBooks.find(b => b.BookId === bookId) : initBooks[self?.ScanIndex() - 1];

        if (!book) {
            self.IsScanningActive(true);
            return;
        }

        HsbDataService.GetBookTitleAudio(book.BookId, function (bookData) {
            let audioUrl = bookData.Pages[0].SpokenAudioUrl;
            _hsb_loadandplayaudio(
                audioUrl,
                function () {
                    self.IsScanningActive(true);
                },
                readerSettings.AttentionSoundVolume,
                function () {
                    self.IsScanningActive(false);
                }
            );
        });
    };

    this.StartScanningFromTop = function () {
        if (initBooks) self.StartScanningBooks(initBooks);
        else if (initCategories) self.StartScanningCategories(initCategories);
    };

    this.StartScanningCategories = function (categories) {
        initBooks = [];
        let items = categories.map((c, i) => ({
            thumb: c.Thumbnail,
            index: i + 1,
            name: c.RubriekNaam,
            id: c.Id,
            cssclass: CssClassEnum.Category
        }));
        self.StartScanningItems(items);
    };

    this.StartScanningBooks = function (books) {
        initBooks = books;
        let items = books.map((b, i) => ({
            thumb: b.CoverThumburl,
            index: i + 1,
            name: b.Titel,
            id: b.BookId,
            cssclass: CssClassEnum.Book
        }));
        if (initLevel)
            items.push({
                id: "BACK",
                thumb: "images/back-button.png",
                index: items.length + 1,
                cssclass: CssClassEnum.Back,
                name: "Terug"
            });
        self.StartScanningItems(items);
    };

    this.StartScanningItems = function (items) {
        self.ScanItems(items);
        self.ScanIndex(1);
        if (self.Scanning != ScanningEnum.None) {
            self.playTitleAudio();
        }
        setTimeout(function () {
            self.Scrollhandlers().resetScroll();
        }, 500);
    };

    this.ContinueAfterBook = function () {
        if (self.Scanning != ScanningEnum.None) {
            self.NextScanItem();
            self.IsScanningActive(true);
        } else {
            setTimeout(function () {
                self.Scrollhandlers().resetScroll();
            }, 500);
        }
    };

    function selectItem(item) {
        self.IsScanningActive(false);
        if (item.cssclass === CssClassEnum.Category) {
            EsGetBooks(item.name, initLevel, books =>
                self.StartScanningBooks(books)
            );
        } else if (item.cssclass === CssClassEnum.Book) {
            screen.ScanningLibraryTemp = self;
            screen.ScanningLibrary(null);

            screen.PlayBook(item.id, true);
        } else if (item.cssclass === CssClassEnum.Back)
            self.StartScanningCategories(initCategories);
    }

    this.TogglePause = function () {
        self.ScanningIsPaused = !self.ScanningIsPaused;
    };

    this.PauseScanning = function (pause) {
        self.ScanningIsPaused = pause;
    };

    this.SelectScanItem = function () {
        let item = self.CurrentScanItem();
        if (self.Scanning != ScanningEnum.None) selectItem(item);
    };

    this.ClickItem = function (item) {
        if (self.Scanning == ScanningEnum.Auto || self.Scanning == ScanningEnum.Manual) return;
        if (self.ScanningIsPaused) return;
        self.ScanIndex(item.index);
        selectItem(item);
    };

    this.ExitScanningLibrary = function () {
        self.IsScanningActive(false);
        self.ScanItems([]);
        clearInterval(interval);
        interval = null;
        screen.NavigateTo(
            screen.ScanningLibraryLaunchFromPageNav || { page: "home" }
        );
    };

    this.MenuBack = function () {
        if (initCategories && initCategories.length > 0) {
            self.StartScanningCategories(initCategories);
        }
    };

    this.onBookMouseover = function(book) {
        if (self.Scanning == ScanningEnum.None) {
            self.playTitleAudio(book.id);
        }
    };

    this.InputHandlers = {
        enablePointerSelect: self.Scanning != ScanningEnum.None,

        pageUp: self.PreviousPage,
        pageDown: self.NextPage,
        left: self.PreviousScanItem,
        right: self.NextScanItem,        

        home: self.MenuBack,
        escape: self.ExitScanningLibrary,
        enter: self.SelectScanItem,
        space: self.SelectScanItem,
        pause: self.TogglePause,
        pointerSelect: self.SelectScanItem,
        rightClick: self.NextScanItem
    };
}
