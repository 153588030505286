export function EsGetBooksByQueryObject(esQuery, onResult) {
    var esServer = window["_esServer"];

    //fetch('https://es.edubox.nl/books/_search',
    fetch(esServer+'/_search',
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(esQuery)
        }
    )
        .then((response) => { return response.json(); })
        .then((data) => {

            var booksFound = data.hits.hits
                .map((hit) => ({
                    BookId: hit._id,
                    Auteurs: hit._source.auteurs ? hit._source.auteurs.join(", ") : "",
                    Uitgever: hit._source.uitgever,
                    CoverThumburl: bookUrl(hit._source.thumbnailUrl),
                    Titel: hit._source.titel
                }));

            onResult(booksFound);
        });
}

export function EsGetBooks(rubriekWeergaveNaam, level, onResult) {
    var esQuery = {
        "post_filter": {
            "bool": {
                "must": []
            }
        },
        "size": 500,
        "sort": [{ "titel.keyword": "asc" }],
        "_source": [
            "titel",
            "thumbnailUrl",
            "auteurs",
            "uitgever"
        ]
    };

    if (rubriekWeergaveNaam) esQuery.post_filter.bool.must.push({
        term: { "themas.raw": rubriekWeergaveNaam }
    }); //bv "Beroepen"; Fields_RubriekOfCategorie.WeergaveNaam
    if (level) {
        if (level === "makkelijk") level = "Makkelijk";
        if (level === "moeilijk") level = "Moeilijk";
        esQuery.post_filter.bool.must.push({
            term: { "niveauRubriek.raw": level }
        });
    }

    EsGetBooksByQueryObject(esQuery, onResult);
}