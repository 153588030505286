import ko from 'knockout'

export default function PaymentFormModel(activatieCode, screen) {
    var self = this;

    var userDetails = screen.UserDetails();

    this.Step = ko.observable('start');

    this.PaymentSessionError = ko.observable();

    this.InvoiceRequestStatus = ko.observable();
    this.iDEALRequestStatus = ko.observable();

    var pageContent = null;
    if (activatieCode) {
        pageContent = screen.SiteData.RegularPages["start-betaling-cadeau"];
    } else {
        switch (userDetails.SubscriptionStatus()) {
            case "Actief":
                pageContent = screen.SiteData.RegularPages["start-betaling-verlengen"];
                break;
            case "Wacht op betaling":
                var paymentMethod = userDetails.PaymentMethod();                
                switch (paymentMethod) {
                    case 'iDEAL':
                        self.PaymentSessionError('iDEAL boodschap: ' + userDetails.LaatsteTransactieBoodschap());
                        self.iDEALRequestStatus('Transactienummer: ' + userDetails.LaatsteIdealTransactieId());
                        pageContent = screen.SiteData.RegularPages["ideal-probleem"];
                        self.Step("waiting-on-payment");
                        break;
                    case 'Factuur':
                        pageContent = screen.SiteData.RegularPages["factuur-verzoek"];
                        self.InvoiceRequestStatus("-");
                        self.Step("waiting-on-payment");
                        break;
                    //case 'Kas':
                    default:
                        pageContent = screen.SiteData.RegularPages["start-betaling-nieuw-abonnement"];
                        break;
                }
                break;
            case "Verlopen":
                pageContent = screen.SiteData.RegularPages["start-betaling-verlopen"];
                break;
            default:
                //case "Betaald, uitgestelde ingangsdatum":
                pageContent = screen.SiteData.RegularPages["start-betaling-nieuw-abonnement"];
                break;
        }
    }

    this.Uitleg = ko.observable(pageContent ? pageContent.Inhoud : null);

    this.MollieBanksList = ko.observable();
    this.SelectedBank = ko.observable();

    this.CanceliDEAL = function () {
        HsbDataService.CanceliDEAL(function () {
            self.iDEALRequestStatus(null);
            self.PaymentSessionError('iDEAL betaling geannuleerd');
            self.Step('start');
        });
    }
    this.CancelInvoice = function () {
        HsbDataService.CancelInvoice(function () {
            self.InvoiceRequestStatus(null);
            self.PaymentSessionError('Factuurverzoek geannuleerd');
            self.Step('start');
        });
    }
    this.SubscriptionType = ko.observable();

    this.SelectiDEAL = function () {
        this.InitiateMollieIDEALSession();
    }

    this.InitiateMollieIDEALSession = function () {
        HsbDataService.InitiateMollieIDEALSession(
            self.SubscriptionType(),
            activatieCode || "",
            mollieSession => {                
                window.location = mollieSession.RedirectUrl;
            },
            error => { self.PaymentSessionError(error); });
    }

    this.SendInvoiceRequest = function () {
        HsbDataService.SendInvoiceRequest(
            self.SubscriptionType(),
            activatieCode || "",
            result => {
                var page = screen.SiteData.RegularPages["factuur-verzoek"];
                self.InvoiceRequestStatus(page ? page.Inhoud : 'Wacht op ontvangst van betaling');
                self.Step('waiting-on-payment');
                userDetails.SubscriptionStatus("Wacht op betaling");
                userDetails.PaymentMethod('Factuur');
            },
            error => { self.PaymentSessionError(error); })
    }
}