import ko from 'knockout'
import { EsGetBooksByQueryObject } from './booksquery'

export default function SearchAppModel(screen, siteData, userDetailsModel) {
    const self = this;
    //this.CurrentQueryUrl = ko.observable();

    function storeMostRecentQuery(urlQuery) {
        Cookies.set('MostRecentQuery', urlQuery);
        window['_MostRecentQuery'] = urlQuery;
    }
    function getMostRecentQuery() {
        var initialQuery = Cookies.get('MostRecentQuery');
        return initialQuery || window['_MostRecentQuery'];         
    }
    function updateQuery(query, urlQuery) {
        screen.CurrentQueryObject = query;
        //self.CurrentQueryUrl(urlQuery);
        storeMostRecentQuery(urlQuery);
    }

    var initialQuery = getMostRecentQuery();
    var esServer = window["_esServer"];

    this.StartScanningLibrary = function () {

        function start() {
            screen.ScanningLibraryLaunchFromPageNav = { page: 'zoeken' };
            screen.SearchApp(null);
            EsGetBooksByQueryObject(screen.CurrentQueryObject, books => screen.StartScanningLibrary(books));
        }
        if (screen.IsLoggedIn()) start();
        else {
            screen.ShowSignInWithMessage("U moet ingelogd zijn en een actief abonnement hebben om verder te gaan.", function onSignIn() {
                start();
            }, function onSignInCancel() {
                    screen.NavigateTo({ page: 'demo' });
            });
        }
    }

    var cookieAttentionMessage = Cookies.get('IsAttentionMessageVisible');
    
    this.IsAttentionMessageVisible = ko.observable(!cookieAttentionMessage);
    this.CloseAttentionMessage = function () {
        Cookies.set('IsAttentionMessageVisible', 'hidden');
        self.IsAttentionMessageVisible(false);
    }

    function onResults(results) {
        console.log('results', results);
        if (results && results.hits && results.hits.hits && results.hits.hits.length > 0) {
            var booksFound = results.hits.hits.map(hit => ({ BookId: hit._id }));
            screen.TempBooksPlaylist = booksFound;
        }        
    }

    this.SkParams = {
        lastReadByMeMaxValue: userDetailsModel ? userDetailsModel.LastReadByMeMaxValue : -1,
        lastReadByMeField: "lastReadBy_" + (userDetailsModel ? userDetailsModel.UserId() : ""),
        aantalKeerGelezenInAfgelopenMaandMaxValue: siteData.AantalKeerGelezenInAfgelopenMaandMaxValue,
        leeftijdMaxValue: siteData.LeeftijdMaxValue,
        userId: userDetailsModel ? userDetailsModel.UserId() : null,
        initialQuery: initialQuery,
        storedQueries: userDetailsModel ? userDetailsModel.StoredSearchQueries() : [],
        esServer: esServer
    };
    this.IsSignedIn = !!siteData.UserDetails;
    this.Handlers = {
        isFavorite: id => screen.IsFavorite({BookId: id}),
        addFavorite: (id, onDone) => screen.ToggleFavoriteById(id, onDone),
        removeFavorite: (id, onDone) => screen.ToggleFavoriteById(id, onDone),
        toggleFavorite: (id, onDone) => screen.ToggleFavoriteById(id, onDone),
        addQuery: (name, queryUrl, onDone) => HsbDataService.AddQuery({ Name: name, Query: queryUrl }, allQueries => {
            onDone(allQueries);
            userDetailsModel.StoredSearchQueries(allQueries);
        }),
        removeQuery: (id, onDone) => HsbDataService.RemoveQuery(id, allQueries => {
            onDone(allQueries);
            userDetailsModel.StoredSearchQueries(allQueries);
        }),
        storedQueries: () => userDetailsModel ? userDetailsModel.StoredSearchQueries() : [],
        //playBook: id => screen.PlayBook(id),
        queryProcessor: updateQuery,
        onResults: onResults
    }
}
