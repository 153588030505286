import React from 'react'

var hostAPI = window._hsb_sk_api;

class BookHit extends React.Component {
    constructor(props, context) {
        super(props, context);
        const result = props.result;        
        this.state = {
            favoriteClass: hostAPI.handlers.isFavorite(result._id) ? "favorited" : ""           
        };
    }

    toggleFavorite(id, e) {
        e.stopPropagation();
        e.preventDefault();
        hostAPI.handlers.toggleFavorite(id, isFavorite => {
            this.setState({ favoriteClass: isFavorite ? "favorited" : "" });
        });
        return false;
    }

    render() {
        const url = "#book=" + this.props.result._id;
        return (
            <div className={this.props.bemBlocks.item().mix(this.props.bemBlocks.container("item"))} key={this.props.result._id} data-qa="hit">
                <a href={url}>
                    <img data-qa="thumbnailUrl" alt="presentation" className={this.props.bemBlocks.item("thumbnailUrl")} src={"http://sprekendboek-static.edubox.nl/" + this.props.result._source.thumbnailUrl} width="170" height="240" />
                    <span className={this.state.favoriteClass + " favorite"} onClick={this.toggleFavorite.bind(this, this.props.result._id)} />
                    <div className="additional">
                        <div data-qa="titel" className="titel">{this.props.result._source.titel}</div>
                        <div data-qa="uitgever" className="uitgever">{this.props.result._source.uitgever}</div>
                        <div data-qa="auteurs" className="auteurs">{this.props.result._source.auteurs.join(", ")}</div>
                    </div>
                </a>
            </div>
        );
    }
}

export default BookHit;