import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import ko from 'knockout' 
import ScreenViewModel from './ScreenViewModel'
require('./custom-bindings')

function startApp() {   
    
    function bindEmpty()
    {
        var screenModel = new ScreenViewModel({});
        ko.applyBindings(screenModel);
    }
    HsbDataService.GetSiteInitData(data => {
        var screenModel = new ScreenViewModel(data);
        window["_esServer"] = data.ElasticServer;
        window["_screenModel"] = screenModel;
        ko.applyBindings(screenModel);
    }, bindEmpty);    
}
startApp();