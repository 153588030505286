import ko from 'knockout'

export default function ReaderSettingsModel(data, userId, defaultAttentionImages, defaultAttentionSounds, baseUploadPath, screen) {
    var self = this;

    this.IsSharedUserAccount = screen.SiteData.UserDetails && screen.SiteData.UserDetails.UserName === "Corona";

    this.Karaoke = ko.observable(data.Karaoke);
    this.BrowseAutomatically = ko.observable(data.BrowseAutomatically);
    this.MustWaitForAudioFinish = ko.observable(data.MustWaitForAudioFinish);
    this.RepeatSingleBook = ko.observable(typeof (data.RepeatSingleBook) == "undefined" ? true : data.RepeatSingleBook);
    this.ReadBookTitles = ko.observable(typeof (data.ReadBookTitles) == "undefined" ? false : data.ReadBookTitles);

    var savedData = data;
    this.OnBeforeNavigate = function () {
        var newData = JSON.parse(JSON.stringify(self.GetSerializeObject()));
        var isEqual = _.isEqual(newData, savedData);
        return isEqual;
    }

    function getFileOptions(defaultFiles, uploadedFiles) {
        var options = [{ Name: 'Geen selectie' }];
        if (defaultFiles) for (var i = 0; i < defaultFiles.length; i++) options.push({ Id: defaultFiles[i].Id, Name: defaultFiles[i].Name, Ext: defaultFiles[i].Ext });
        if (uploadedFiles) for (var j = 0; j < uploadedFiles.length; j++) options.push({ Id: uploadedFiles[j].Id, Name: uploadedFiles[j].Name, Ext: uploadedFiles[j].Ext });
        return options;
    }

    this.SelectedAttentionImageId = ko.observable(data.SelectedAttentionImageId);
    this.UploadedAttentionImages = ko.observableArray(data.UploadedAttentionImages || []);
    this.AttentionImageOptions = ko.computed(() => getFileOptions(defaultAttentionImages, self.UploadedAttentionImages()));

    this.SelectedAttentionSoundId = ko.observable(data.SelectedAttentionSoundId);
    this.UploadedAttentionSounds = ko.observableArray(data.UploadedAttentionSounds || []);
    this.AttentionSoundOptions = ko.computed(() => getFileOptions(defaultAttentionSounds, self.UploadedAttentionSounds()));

    this.AttentionSoundVolume = ko.observable(data.AttentionSoundVolume);
    this.AttentionSoundDelay = ko.observable(typeof (data.AttentionSoundDelay) == "undefined" ? 1500 : data.AttentionSoundDelay)
    this.BrowseButtonLocation = ko.observable(data.BrowseButtonLocation); // empty to disable browse button, otherwise mid-right, right-below, right-above, mid-below
    this.DisableEffectSounds = ko.observable(data.DisableEffectSounds);
    this.SearchAsHomePage = ko.observable(data.SearchAsHomePage);  // default or search

    //this.EnableScanning = readerSettings ? (readerSettings.EnableScanning === undefined ? true : readerSettings.EnableScanning) : false;

    this.Scanning = ko.observable(data.IsNewUser ? false : (data.Scanning === undefined ? ScanningEnum.Auto : data.Scanning));

    this.EnableScrollButtons = ko.observable(data.EnableScrollButtons);

    this.Font = ko.observable(data.Font);
    this.FontSize = ko.observable(data.FontSize);

    this.PageColor = ko.observable(data.PageColor);
    this.TextColor = ko.observable(data.TextColor);
    this.KaraokeColor = ko.observable(data.KaraokeColor);

    this.UseTextColor = ko.observable(!!data.TextColor);
    this.UsePageColor = ko.observable(!!data.PageColor);
    this.UseKaraokeColor = ko.observable(!!data.KaraokeColor);

    this.KaraokeUnderline = ko.observable(data.KaraokeUnderline);

    this.UseTextColor.subscribe(c => { if (c && !self.TextColor()) self.TextColor('#000000'); });
    this.UsePageColor.subscribe(c => { if (c && !self.PageColor()) self.PageColor('#ffffff'); });    
    this.UseKaraokeColor.subscribe(c => { if (c && !self.KaraokeColor()) self.KaraokeColor('#FF0000'); });

    this.NumberOfScanItemColumns = ko.observable(typeof (data.NumberOfScanItemColumns) == "undefined" ? 5 : data.NumberOfScanItemColumns);

    var actionAfterBookFinish = "Menu";
    if (typeof (data.ActionAfterBookFinish) == "undefined") {
        actionAfterBookFinish = data.RepeatSingleBook ? "Repeat" : "Menu";
    } else actionAfterBookFinish = data.ActionAfterBookFinish;
    this.ActionAfterBookFinish = ko.observable(actionAfterBookFinish);

    this.ScanningInterval = ko.observable(typeof (data.ScanningInterval) == "undefined" ? 2 : (data.ScanningInterval > 8 ? 2 : data.ScanningInterval));

    function getFileUrl(fileItemId, allFileItems) {        
        var fileItem = _(allFileItems).find(i => i.Id === fileItemId);
        if (!fileItem || !fileItem.Id) return null;
        return baseUploadPath + "/" + fileItem.Id + "." + fileItem.Ext;
    }

    this.PlayAttentionSound = function () {        
        var soundUrl = getFileUrl(self.SelectedAttentionSoundId(), self.AttentionSoundOptions());
        var volume = parseInt(self.AttentionSoundVolume(), 10);
        _hsb_loadandplayaudio(soundUrl, null, volume);
    }

    this.AttentionImagePreviewUrl = ko.computed(function () {
        var imageUrl = getFileUrl(self.SelectedAttentionImageId(), self.AttentionImageOptions());
        return imageUrl;
    });

    this.UploadAttentionImageFilePicked = ko.observable();
    this.UploadAttentionSoundFilePicked = ko.observable();

    this.IsUserImageFileSelected = ko.computed(function () {
        var fileId = self.SelectedAttentionImageId();        
        return fileId && fileId.indexOf(userId+'/') > -1;
    })

    function deleteFile(fileItem, onDone) {
        var fileIdParts = fileItem.Id.split('/');
        var userFileId = fileIdParts[fileIdParts.length - 1];
        HsbDataService.RemoveFile(userFileId, fileItem.Ext, onDone);
    }

    this.DeleteImage = function () {
        var fileId = self.SelectedAttentionImageId();
        var fileItem = _(self.UploadedAttentionImages()).find(i => i.Id === fileId);
        deleteFile(fileItem, () => self.UploadedAttentionImages.remove(fileItem));
    }

    this.IsUserSoundFileSelected = ko.computed(function () {
        var fileId = self.SelectedAttentionSoundId();
        return fileId && fileId.indexOf(userId + '/') > -1;
    })

    this.DeleteSound = function () {
        var fileId = self.SelectedAttentionSoundId();
        var fileItem = _(self.UploadedAttentionSounds()).find(i => i.Id === fileId);
        deleteFile(fileItem, () => self.UploadedAttentionSounds.remove(fileItem));
    }

    this.UploadImageResult = ko.observable();
    this.UploadSoundResult = ko.observable();

    var MaxFileSize = 1 * 1024 * 1024;

    function checkFileExtension(fileName, allowedExtensions) {
        var fileNameParts = fileName.toLowerCase().split('.');
        var fileExt = fileNameParts[fileNameParts.length - 1];
        return allowedExtensions.indexOf(fileExt) > -1 ? fileExt : null;
    }

    function startUpload(file, allowedExtensions, onDone, onError) {        
        if (file.size > MaxFileSize) return onError("Maximale bestandsgrootte is 1MB");
        var extension = checkFileExtension(file.name, allowedExtensions);
        if (!extension) return onError("Alleen " + allowedExtensions.join(", ") +" toegestaan");
        _UploadFileInput2('HsbUploadService', 'UploadFile', null, file, null,
            serverFileId => {
                var id = userId + "/" + serverFileId;
                onDone({ Id: id, Name: file.name, Ext: extension });
            });
    }
    
    this.UploadImage = function () {
        self.UploadImageResult(null);
        startUpload(self.UploadAttentionImageFilePicked(), ["jpg", "jpeg", "png", "gif"], item => {
            self.UploadImageResult(item.Name + " succesvol ge-upload");            
            self.UploadedAttentionImages.push(item);
            self.SelectedAttentionImageId(item.Id);
        }, errorMessage => { self.UploadImageResult(errorMessage); });
    }

    this.UploadSound = function () {
        self.UploadSoundResult(null);
        startUpload(self.UploadAttentionSoundFilePicked(), ["mp3"], item => {
            self.UploadSoundResult(item.Name + " succesvol ge-upload");
            self.UploadedAttentionSounds.push(item);
            self.SelectedAttentionSoundId(item.Id);
        }, errorMessage => { self.UploadSoundResult(errorMessage); });
    }

    

    this.SubmitResult = ko.observable();

    this.AttentionSoundRecorderVisible = ko.observable(false);
    this.ToggleAttentionSoundRecorder = function () {
        self.AttentionSoundRecorderVisible(!self.AttentionSoundRecorderVisible());
    }        

    function CheckTranscodedUrl(videoId, onTranscodingReceived) {
        var transcodingPollAttempts = 0;

        function poll() {
            HsbDataService.GetTranscodedRecordingUrl(videoId, function (serverFileId) {
                transcodingPollAttempts++;

                if (serverFileId != null) {
                    onTranscodingReceived(serverFileId);                    
                } else if (transcodingPollAttempts < 50) {
                    var timer2 = setTimeout(poll, 2000);
                }
            });
        }

        var timer1 = setTimeout(poll, 2000);
    }

    this.VerwerkOpname = function (videoId) {
        CheckTranscodedUrl(videoId, function (serverFileId) {
            var streamName = prompt('Geef uw opname een naam');
            var id = userId + "/" + serverFileId;
            var item = { Id: id, Name: streamName, Ext: "mp4" };
            
            self.UploadSoundResult(item.Name + " succesvol ge-upload");
            self.UploadedAttentionSounds.push(item);
            self.SelectedAttentionSoundId(item.Id);
        })
    };

    this.StoreSettings = function () {

        self.SubmitResult(null);
        var readerSettingsData = self.GetSerializeObject();

        var readerSettingsDataJSON = JSON.stringify(readerSettingsData);
        HsbDataService.StoreReaderSettings(readerSettingsDataJSON, function () {
            savedData = JSON.parse(readerSettingsDataJSON);
            self.SubmitResult("De instellingen zijn succesvol opgeslagen");            
            screen.UserDetails().ReaderSettings(readerSettingsData);
        }, function () { self.SubmitResult("Er was een probleem met het opslaan van de instellingen."); });
    }

    this.GetSerializeObject = function () {
        return {
            Karaoke: self.Karaoke(),
            BrowseAutomatically: self.BrowseAutomatically(),
            RepeatSingleBook: self.RepeatSingleBook(),
            MustWaitForAudioFinish: self.MustWaitForAudioFinish(),
            SelectedAttentionImageId: self.SelectedAttentionImageId(),
            UploadedAttentionImages: self.UploadedAttentionImages(),
            SelectedAttentionSoundId: self.SelectedAttentionSoundId(),
            UploadedAttentionSounds: self.UploadedAttentionSounds(),
            BrowseButtonLocation: self.BrowseButtonLocation(),
            DisableEffectSounds: self.DisableEffectSounds(),
            SearchAsHomePage: self.SearchAsHomePage(),
            Scanning: self.Scanning(),
            PageColor: self.UsePageColor() ? self.PageColor() : null,
            TextColor: self.UseTextColor() ? self.TextColor() : null,
            KaraokeColor: self.UseKaraokeColor() ? self.KaraokeColor() : null,
            Font: self.Font(),
            FontSize: self.FontSize(),
            AttentionSoundVolume: parseInt(self.AttentionSoundVolume(), 10),
            AttentionSoundDelay: parseInt(self.AttentionSoundDelay(), 10),
            NumberOfScanItemColumns: parseInt(self.NumberOfScanItemColumns(), 10),
            ActionAfterBookFinish: self.ActionAfterBookFinish(),
            ScanningInterval: parseInt(self.ScanningInterval(), 10),
            KaraokeUnderline: self.KaraokeUnderline(),
            EnableScrollButtons: self.EnableScrollButtons(),
            ReadBookTitles: self.ReadBookTitles()     
        };
    }
}